<div class="modal-content">
    <div *ngIf="title" class="modal-body">
        <div class="message">
            <img src="assets/icon/security-key-success.png" alt="" srcset="">
            <h5>{{ title }}</h5>
        </div>
    </div>

    <div *ngIf="message" class="modal-body">
        <div class="message">
            <p>{{ message }}</p>
        </div>
    </div>
    <div class="modal-footer">
        <div *ngIf="type && type === 'REG'" (click)="closeModal()"
            style="font-size: 14px; cursor: pointer; color: black;">
            Continue
        </div>
        <div *ngIf="type && type === 'FP'" (click)="closeModal()"
            style="font-size: 14px; cursor: pointer; color: black;">
            Okay
        </div>
    </div>
</div>