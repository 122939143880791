import { Injectable } from '@angular/core';
import { FirebaseServiceService } from '../firebase-service/firebase-service.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private firebaseService: FirebaseServiceService,
  ) { }

  async initializeApp(): Promise<void> {
    console.log('initialize app');
    try {
      /** test for firebase token for macOS and iOS */
      this.firebaseService.requestPermission();

      /** firebase token for window, android */
      // this.firebaseService.getFirebaseToken();

      /** test for firebase token */
      // const token = await this.secureStorageService.getValue('firebaseToken');
      // if (token) {
      //   console.log('token', token);
      // } else {
      //   throw new Error('Token not found');
      // }
    } catch (error) {
      throw error;
    } finally {
    }
  }
}

export function initializeAppFactory(
  appInitializerService: AppInitializerService
): () => Promise<void> {
  return () => appInitializerService.initializeApp();
}
