import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, IonicSafeString, NavController } from '@ionic/angular';
import { LoadingService } from '../helper/loading.service';
import { Clipboard } from '@capacitor/clipboard';
import { Router } from '@angular/router';
import { SecurityKeySuccessComponent } from 'src/app/shared/components/security-key-success/security-key-success.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SecureStorageService } from '../storage/secure-storage.service';
import { AuthService } from '../auth/auth.service';
import { EmailToolTipsComponent } from 'src/app/shared/components/email-tool-tips/email-tool-tips.component';

@Injectable({
  providedIn: 'root',
})
export class CustomAlertService {
  passwordForm!: FormGroup;
  passwordValidations = [
    { message: 'Minimum 8 characters', valid: false },
    { message: 'At least 1 uppercase (A to Z)', valid: false },
    { message: 'At least 1 number (0 to 9)', valid: false },
    {
      message: 'At least 1 special character (!, #, $, %, etc.)',
      valid: false,
    },
  ];

  constructor(
    private helper: LoadingService,
    private alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private secureStorage: SecureStorageService,
    private authService: AuthService,
    private navCtrl: NavController
  ) {
    this.passwordForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          Validators.pattern('^(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*]).+$'),
        ],
      ],
    });

    this.passwordForm.controls['password'].valueChanges.subscribe((value) => {
      this.updateValidationStatus(value);
    });
  }

  updateValidationStatus(value: string) {
    this.passwordValidations[0].valid = value.length >= 8;
    this.passwordValidations[1].valid = /[A-Z]/.test(value);
    this.passwordValidations[2].valid = /\d/.test(value);
    this.passwordValidations[3].valid = /[!@#$%^&*]/.test(value);
  }

  isPasswordValid(password: string): boolean {
    const lengthValid = password.length >= 8;
    const uppercaseValid = /[A-Z]/.test(password);
    const numberValid = /\d/.test(password);
    const specialValid = /[!@#$%^&*]/.test(password);

    return lengthValid && uppercaseValid && numberValid && specialValid;
  }

  async showPasswordAlert(): Promise<any> {
    let alertPass;
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      // header: new IonicSafeString(`Set Security Key.`),
      message: new IonicSafeString(`
       <div class="alert-content">
        <div class="header-content" style="justify-content: space-between;">
          <div style="display: flex;">
            <div style="display: flex;
                padding: 6px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid #aeacac;">
              <img src="assets/icons/protected.svg" alt="dynamic image" />
            </div>
            <h5 style="color: #000000;">${this.translate.instant('SIGN_UP.setKey')}</h5>
          </div>
          <ion-icon name="close" id="close-btn"  style="margin-top: -4px"></ion-icon>
        </div>

        <p>To keep your wallet secure, please set your security key password for your next login. Please be aware that if you forget your security key, you won't be able to access your wallet account.</p>
        <ion-input
          id="password-input"
          class="password-input-class"
          placeholder="Security Key"
          type="password"
          (ionInput)="updateValidationStatus($event.target.value)"
          aria-label="aid:security-key-input" contentDescription="aid:security-key-input"
        ></ion-input>
        <div class="password-validation">
        <ion-item lines="none">
          <ion-icon id="length-check" aria-hidden="true" name="ellipse-outline" slot="start" color="medium"></ion-icon>
          <ion-label aria-label="aid:minimum8-label" contentDescription="aid:minimum8-label" >Minimum 8 characters</ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-icon id="uppercase-check" aria-hidden="true" name="ellipse-outline" slot="start" color="medium"></ion-icon>
          <ion-label aria-label="aid:uppercase-label" contentDescription="aid:uppercase-label" >At least 1 uppercase (A to Z)</ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-icon id="number-check" aria-hidden="true" name="ellipse-outline" slot="start" color="medium"></ion-icon>
          <ion-label aria-label="aid:number-label" contentDescription="aid:number-label" >At least  1 number (0 to 9)</ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-icon id="special-check" aria-hidden="true" name="ellipse-outline" slot="start" color="medium"></ion-icon>
          <ion-label aria-label="aid:special-characters-label" contentDescription="aid:special-characters-label" >At least 1 special character (!, #, $, %, etc.)</ion-label>
        </ion-item>
        </div>
      </div>
      `),
      buttons: [
        {
          text: 'Set',
          cssClass: 'set-button',
          handler: (data) => {
            const password = (
              document.getElementById('password-input') as HTMLInputElement
            ).value;
            if (this.isPasswordValid(password)) {
              alertPass = password;
              // Handle the valid password
              return true;
            } else {
              return false; // Keep the alert open
            }
          },
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();

    const passwordInput = document.getElementById(
      'password-input'
    ) as HTMLInputElement;
    const setButton = document.querySelector(
      'ion-alert .alert-button.set-button'
    ) as HTMLButtonElement;

    setButton.disabled = true;
    setButton.classList.add('disabled');
    passwordInput.addEventListener('input', () => {
      this.updateValidationIcons(passwordInput.value);
      // setButton.disabled = !this.isPasswordValid(passwordInput.value);
      const isValid = this.isPasswordValid(passwordInput.value);
      setButton.disabled = !isValid;
      if (isValid) {
        setButton.classList.remove('disabled');
      } else {
        setButton.classList.add('disabled');
      }
    });

    const close = document.getElementById('close-btn') as HTMLButtonElement;

    close.addEventListener('click', () => {
      this.navCtrl.navigateRoot('auth/sign-in');
      alert.dismiss();
    });

    // Wait for the alert to be dismissed
    const result = await alert.onDidDismiss();

    // Access the data after the alert is dismissed
    // const password = result.data ? result.data.values['password-input'] : null;
    const password = alertPass;
    return password;
  }

  updateValidationIcons(password: string) {
    const lengthCheck = document.getElementById('length-check');
    const uppercaseCheck = document.getElementById('uppercase-check');
    const numberCheck = document.getElementById('number-check');
    const specialCheck = document.getElementById('special-check');

    lengthCheck!.setAttribute(
      'name',
      password.length >= 8 ? 'checkmark-circle' : 'ellipse-outline'
    );
    lengthCheck!.setAttribute(
      'color',
      password.length >= 8 ? 'success' : 'medium'
    );

    uppercaseCheck!.setAttribute(
      'name',
      /[A-Z]/.test(password) ? 'checkmark-circle' : 'ellipse-outline'
    );
    uppercaseCheck!.setAttribute(
      'color',
      /[A-Z]/.test(password) ? 'success' : 'medium'
    );

    numberCheck!.setAttribute(
      'name',
      /\d/.test(password) ? 'checkmark-circle' : 'ellipse-outline'
    );
    numberCheck!.setAttribute(
      'color',
      /\d/.test(password) ? 'success' : 'medium'
    );

    specialCheck!.setAttribute(
      'name',
      /[!@#$%^&*]/.test(password) ? 'checkmark-circle' : 'ellipse-outline'
    );
    specialCheck!.setAttribute(
      'color',
      /[!@#$%^&*]/.test(password) ? 'success' : 'medium'
    );
  }

  close() {
  }

  async showConfirmPasswordAlert(firstKey: string, type: string, memberID?: any): Promise<any> {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      // header: new IonicSafeString(`Set Security Key.`),
      message: new IonicSafeString(`
       <div class="alert-content">
       <div class="header-content" style="justify-content: space-between;width: 103%;">
        <div style="display: flex;">
          <div style="display: flex;
                padding: 6px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid #aeacac;">
            <img src="assets/icons/protected.svg" alt="dynamic image" />
          </div>
          <h5 style="color: #000000;">Confirm your Security Key.</h5>
        </div>
        <ion-icon name="close" id="close-btn" style="margin-top: -4px"></ion-icon>
       </div>
        <ion-input
          id="password-input"
          class="password-input-class"
          placeholder="Security Key"
          type="password"
          aria-label="aid:password-input" contentDescription="aid:password-input"
        ></ion-input>
      </div>
      `),
      buttons: [
        type === 'register'
          ? {
            text: 'Copy',
            handler: () => {
              const inputs = document.querySelectorAll('ion-alert input');
              let allFilled = true;
              inputs.forEach((input) => {
                if ((input as HTMLInputElement).value.trim() === '') {
                  allFilled = false;
                  this.helper.presentToast('Fill all the keys!');
                }
              });
              if (allFilled) {
                this.copyToClipboard(firstKey);
              }
              return false; // Keep the alert open
            },
          }
          : '',
        {
          text: 'Confirm',
          cssClass: 'set-button',
          handler: async (data) => {
            const password = (
              document.getElementById('password-input') as HTMLInputElement
            ).value;

            if (firstKey === password) {
              if (memberID) {
                await this.generateRDN(memberID);
              }
              const modalRef = this.modalService.open(
                SecurityKeySuccessComponent,
                {
                  size: 'sm',
                  backdrop: false,
                  windowClass: 'custom-modal-backdrop1 custom-modal-position1',
                }
              );
              modalRef.componentInstance.title =
                'Your Security Key created successfully.';
              modalRef.componentInstance.type = 'REG';
              return true; // Dismiss the alert
            } else {
              this.helper.presentToast('Keys do not match!');
              return false; // Keep the alert open
            }
          },
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();

    const close = document.getElementById('close-btn') as HTMLButtonElement;

    close.addEventListener('click', () => {
      this.router.navigateByUrl('/sign-up');
      alert.dismiss();
    });

    // Access the data after the alert is dismissed
    // const password = result.data ? result.data.values['password-input'] : null;
    const password = firstKey;
    return password;
  }

  async copyToClipboard(text: string): Promise<void> {
    try {
      await Clipboard.write({
        string: text,
      });
      this.helper.presentToast('Text copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy text: ', err);
      this.helper.presentToast('Failed to copy text to clipboard.');
    }
  }

  async showSecurityAlert(): Promise<any> {
    let alertPass;
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      // header: new IonicSafeString(`Set Security Key.`),
      message: new IonicSafeString(`
       <div class="alert-content">
       <div class="header-content">
        <img src="assets/icons/protected.svg" alt="dynamic image" /> <h5>Enter your Security Key.</h5> <ion-icon name="close" id='close-btn'></ion-icon>
       </div>
        <div style="display: flex;align-items: center;">
        <ion-input
          id="password-input"
          class="password-input-class"
          placeholder="Security Key"
          type="password"
          aria-label="aid:password-input" contentDescription="aid:password-input"
        ></ion-input>
        <img src="assets/icon/email-alert.png" alt="" srcset="" style="cursor: pointer;" id='email-tooltip'>
        </div>
      </div>
      `),
      buttons: [
        {
          text: 'Next',
          cssClass: 'set-button',
          handler: (data) => {
            const password = (
              document.getElementById('password-input') as HTMLInputElement
            ).value;
            alertPass = password;
            return true;
          },
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();

    const close = document.getElementById('close-btn') as HTMLButtonElement;

    close.addEventListener('click', () => {
      alert.dismiss();
    })

    const emailToolTips = document.getElementById('email-tooltip') as HTMLButtonElement;

    emailToolTips.addEventListener('click', () => {
      const modalRef = this.modalService.open(EmailToolTipsComponent, {
        size: 'sm',
        backdrop: false,
        windowClass: 'custom-modal-backdrop custom-modal-position'
      })
      modalRef.componentInstance.type = "sign_in";
    })

    // Wait for the alert to be dismissed
    const result = await alert.onDidDismiss();

    // Access the data after the alert is dismissed
    // const password = result.data ? result.data.values['password-input'] : null;
    const password = alertPass;
    return password;
  }

  async showTransferAlert(): Promise<any> {
    let alertPass;
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      // header: new IonicSafeString(`Set Security Key.`),
      message: new IonicSafeString(`
        <div class="alert-content">
          <div class="header-content">
            <ion-icon name="close" id='close-btn'></ion-icon>
          </div>
          <div>
            <p>You are now entering a MediEarth internal wallet address. To proceed with a transfer to this address, kindly use the Internal Transfer function to complete the transaction.Thanks.</p>
          </div>
        </div>
      `),
      buttons: [
        {
          text: 'OK',
          cssClass: 'set-button',
          handler: (data) => {
            return true;
          },
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();

    const close = document.getElementById('close-btn') as HTMLButtonElement;

    close.addEventListener('click', () => {
      alert.dismiss();
    })

    // Wait for the alert to be dismissed
    const result = await alert.onDidDismiss();
  }


  /** function for registration come from QR code */

  async generateRDN(id: any) {
    try {
      await this.secureStorage.getOrGenerateValue('device_info', () => this.authService.generateRandomNoAndString());
      const r_no = this.authService.generateRandomNo();
      await this.secureStorage.setValue('r_number', r_no);
      try {
        const postData = {
          memberId: id,
          rNo: r_no
        };
        this.authService.registerCheck(postData).subscribe({
          next: async (res) => {
            if (res.code === '200') {
              await this.secureStorage.setValue('temp_token', res.data.token);
              await this.secureStorage.setValue('member_id', id);
            } else {
              this.helper.presentToast('Registration check failed');
            }
          }
        });
      } catch (error: any) {
        this.helper.presentToast(error.message);
      }
    } catch (error) {

    }
  }
}
