<div *ngIf="slides.length > 0" [ngClass]="type === 'home' ? 'hclass' : null">
  <ng-container *ngIf="type === 'home'; else cardTemplate">
    <ion-card class="myCard" style="background-image: url('{{ image }}');">
      <ion-card-content style="padding-top: 8px;padding-bottom: 8px;">
        <ion-grid>
          <ion-row>
            <ion-col size="10" class="myCard-Number">
              <p>
                Visa Balance
              </p>
            </ion-col>
            <ion-col size="2" class="ion-text-end" (click)="goCard()">
              <ion-icon style="font-size: 24px;" class="card-ion-icon" name="arrow-forward-outline"></ion-icon>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12" class="myCard-nft">
              <h3>
                {{ homeTotalBalance | masking : maskingType.BALANCE : shareService.eyeActive }}
                <span>{{ slides[0]?.currency }}</span>
              </h3>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ng-container>
  <ng-template #cardTemplate>
    <swiper-container [modules]="swiperModules" [pagination]="true">
      <swiper-slide *ngFor="let slide of slides">
        <ion-card class="myCardOne" [ngClass]="{ faded: !isCardActive }"
          style="background-image: url('{{ cardImage }}');">
          <ion-card-content>
            <ion-grid>
              <ion-row class="status-row">
                <ion-col size="8">
                  <img src="assets/icon/visa.svg" alt="" />
                </ion-col>
                <ion-col size="4">
                  <button *ngIf="cardStatus !== 'active'" [ngStyle]="{
                      border: '1px solid #bebebe',
                      width: '100%',
                      height: '30px',
                      'background-color': getButtonColor(cardStatus),
                      'border-radius': '10px',
                      'margin-bottom': '20px',
                      color: 'white'
                    }" disabled>
                    {{ cardStatus }}
                  </button>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 5px">
                <ion-col>
                  <p>Carls Heinneken</p>
                  <p style="margin-top: 3px">
                    {{ slide.balance | masking : maskingType.BALANCE : shareService.eyeActive }} {{ slide.currency }}
                  </p>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="8" style="margin-top: 5px">
                  <h5>{{ slide.number | masking : maskingType.CARD : true }}</h5>
                </ion-col>
                <ion-col size="4">
                  <p>{{ slide.status }}</p>
                  <p>{{ slide.expiry | masking : maskingType.DATE : true }}</p>
                </ion-col>
              </ion-row>
            </ion-grid>
            <div *ngIf="!isCardActive" class="overlay-text">
              <h3>Deactivated Card</h3>
              <p>{{ slide.number }}</p>
            </div>
          </ion-card-content>
        </ion-card>
      </swiper-slide>
    </swiper-container>
  </ng-template>
</div>