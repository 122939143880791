import { Pipe, PipeTransform } from '@angular/core';
import { Masking_Type } from '../dto/masking-type.dto';

@Pipe({
  name: 'masking',
})
export class MaskingPipe implements PipeTransform {
  transform(value: string | number, type: Masking_Type, masking: boolean): any {
    if (typeof value === 'number') {
      value = value.toString();
    }

    if (masking == true) {
      switch (type) {
        case Masking_Type.DEFAULT:
          return value;
        case Masking_Type.UNIT:
          return this.convertMaskedUnit(value);
        case Masking_Type.BALANCE:
          return this.convertMaskedBalance(value);
        case Masking_Type.DATE:
          return this.convertMaskedDate(value);
        case Masking_Type.CARD:
          return this.convertMaskedCard(value);
        case Masking_Type.HIDE:
          return 'x'.repeat(value.length);
      }
    } else {
      return value;
    }
  }

  convertMaskedCard(value: string) {
    return 'xxxx xxxx xxxx xxxx';
  }

  convertMaskedDate(value: string) {
    const regex = /^\d{2}\/\d{4}$/;
    if (!value || !regex.test(value)) {
      throw new Error('Invalid date format. Expected format: xx/xxxx');
    }
    const [month, year] = value.split('/');
    const maskedMonth = 'x'.repeat(month.length);
    const maskedYear = 'x'.repeat(year.length);
    return `${maskedMonth}/${maskedYear}`;
  }

  convertMaskedBalance(value: string) {
    if (!value || !value.includes('.')) {
      throw new Error('Invalid amount format. Expected format: XXXX.XX');
    }
    const [integerPart, decimalPart] = value.split('.');
    const maskedIntegerPart = 'x'.repeat(integerPart.length);
    const maskedDecimalpart = 'x'.repeat(decimalPart.length);
    return `${maskedIntegerPart}.${maskedDecimalpart}`;
  }

  convertMaskedUnit(value: string) {
    return 'x'.repeat(value.length);
  }


}
