import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-loan-search',
  templateUrl: './loan-search.component.html',
  styleUrls: ['./loan-search.component.scss'],
})
export class LoanSearchComponent  implements OnInit {

  param: any;
  searchForm!: FormGroup;

  constructor(private navParams: NavParams, private modalController: ModalController, private fb:FormBuilder) { }

  ngOnInit() {
    this.loadForm()
    this.param = this.navParams.get('search');
  }

  loadForm(){
    this.searchForm = this.fb.group({
      keyword : ['']
    })
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      // data: JSON.stringify(this.result)
    });
  }

}
