import { Component, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonicSafeString } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastMessageComponent } from '../toast-message/toast-message.component';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-otp-alert',
  templateUrl: './otp-alert.component.html',
  styleUrls: ['./otp-alert.component.scss'],
})
export class OtpAlertComponent implements OnInit {
  @Input() isModalOpen: boolean = false;
  @ViewChild(ToastMessageComponent) messageToast!: ToastMessageComponent;

  // successAlert = false;
  otpForm: FormGroup;
  showToast = false;
  //listen route
  currentRoute: string = '';

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.otpForm = this.fb.group({
      digit1: ['', [Validators.required, Validators.maxLength(1)]],
      digit2: ['', [Validators.required, Validators.maxLength(1)]],
      digit3: ['', [Validators.required, Validators.maxLength(1)]],
      digit4: ['', [Validators.required, Validators.maxLength(1)]],
      digit5: ['', [Validators.required, Validators.maxLength(1)]],
      digit6: ['', [Validators.required, Validators.maxLength(1)]],
    });
  }

  ngOnInit(): void {
    // Subscribe to form value changes
    this.otpForm.valueChanges.subscribe(() => {
      this.checkFormValidity();
    });

    //listen route
    this.currentRoute = this.router.url;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
      }
    });
  }

  // @ViewChild(IonModal) modal!: IonModal;

  async cancel() {
    // this.modal.dismiss(null, 'cancel');
    this.dismiss();
  }

  checkFormValidity(): void {
    if (this.otpForm.valid) {
      // Perform actions when the form is valid
      this.performAutoSubmit();
    } else {
      // Perform actions when the form is invalid
    }
  }

  performAutoSubmit(): void {
  }

  dismissToast() {
    this.showToast = false;
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async getOtp() {
    // Implement OTP fetching logic here
    this.messageToast?.presentToast('OTP was sent to your email.');
    //determine call method depend on route
    if (this.currentRoute.includes('topup-from-crypto')) {
      this.presentAlert();
    } else if (this.currentRoute.includes('card-registration')) {
      this.createCardSuccessfulAlert();
    }
  }

  showSuccessAlert = false;
  isAlertOpen = false;
  setOpen(isOpen: boolean) {
    this.isAlertOpen = isOpen;
  }

  async createCardSuccessfulAlert() {
    const messageHtml = `<div style="display: flex; flex-direction: column;">
        <div style="display: flex;">
          <img style="color: green; font-size: 24px;" src="../../../../assets/icon/success-tick.svg" alt="">   
          <span style="margin-left: 8px; font-weight: bold;"> Card Registration Complete!</span>
        </div>
        <div style="margin-top: 8px;">
           Your documents have been received and are currently under review. Please check back in the next 3 business days.
        </div>
      </div>`;

    const alert = await this.alertController.create({
      message: new IonicSafeString(messageHtml),
      // buttons: ['Okay'],
      buttons: [
        {
          text: 'Okay',
          handler: async () => {
            await this.dismiss();
            this.router.navigate(['/card-home']);
          },
        },
      ],
      cssClass: 'custom-alert',
    });

    await alert.present();
  }

  async presentAlert() {
    const messageHtml = `
      <div style="display: flex; flex-direction: column;">
        <div style="display: flex;">
          <ion-icon name="checkmark-circle" style="color: green; font-size: 24px;"></ion-icon>
          <span style="margin-left: 8px; font-weight: bold;">Refund to Crypto Complete!</span>
        </div>
        <div  margin-top: 8px;">
          Your balance in card is now topped-up successfully from your wallet's ETH.
        </div>
      </div>
    `;

    const alert = await this.alertController.create({
      message: new IonicSafeString(messageHtml),
      buttons: [
        {
          text: 'Okay',
          handler: async () => {
            await this.dismiss();
            this.router.navigate(['/card-home']);
          },
        },
      ],
      cssClass: 'custom-alert',
    });

    await alert.present();
  }
}
