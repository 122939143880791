import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IonicSlides } from '@ionic/angular';
import { Masking_Type } from '../../dto/masking-type.dto';
import { ShareService } from 'src/app/core/services/auth/share.service';
import SwiperCore from 'swiper';
import { HomeService } from 'src/app/features/home/home.service';
import { Router } from '@angular/router';
import { CryptoService } from 'src/app/features/crypto/crypto.service';

SwiperCore.use([IonicSlides]);

@Component({
  selector: 'app-visa-card',
  templateUrl: './visa-card.component.html',
  styleUrls: ['./visa-card.component.scss'],
})
export class VisaCardComponent {
  @Input() slides: any[] = [];
  @Input() type: any;
  @Input() image: any;
  @Input() cardImage: any;
  swiperModules = [IonicSlides];
  maskingType = Masking_Type;

  @Input() isCardActive: any;
  @Input() cardStatus: any;
  slideNum: number = 0;
  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;
  @Output() valueChange = new EventEmitter<number>();
  homeTotalBalance: string = '0.0';

  constructor(private cryptoService: CryptoService, public shareService: ShareService, private homeService: HomeService, private router: Router) { }

  ngOnInit() {
    /** update balance after topup success */
    this.cryptoService.balanceAfterTransferSuccessSubject$.subscribe(isUpdated => {
      if (isUpdated) {
        this.getTotalBalance();
        // this.cryptoService.setBalanceAfterTopUpSuccess(false); // Reset the flag after updating
      }
    });
    this.getTotalBalance()
  }

  getTotalBalance(id?: string) {
    this.homeService.getHomeTotalBalance(id ?? 'defaultId').subscribe((res) => {
      if (res && res.data && res.data.card && res.data.card.totalBalance !== undefined) {
        if (res.data.card.totalBalance === 0) {
          this.homeTotalBalance = '0.0';
        } else {
          this.homeTotalBalance = res.data.card.totalBalance;
        }
      } else {
        this.homeTotalBalance = '0.0';
      }
    });
  }

  ngAfterViewInit(): void {
    this.slideNum = this.swiperRef?.nativeElement.swiper.activeIndex + 1;
  }

  logActiveIndex() { }

  onSlideChange(event: any) {
    this.slideNum = this.swiperRef?.nativeElement.swiper.activeIndex + 1;
    this.valueChange.emit(this.slideNum);
  }

  getButtonColor(status: string): string {
    switch (status) {
      case 'submitted':
        return '#54A0FF';
      case 'rejected':
        return '#515151';
      default:
        return '#54a0ff';
    }
  }

  goCard() {
    this.router.navigate(['card']);
  }
}
