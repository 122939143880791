import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-email-tool-tips',
  templateUrl: './email-tool-tips.component.html',
  styleUrls: ['./email-tool-tips.component.scss'],
})
export class EmailToolTipsComponent implements OnInit{

    @Input() type : any;
    constructor(private modalService : NgbModal){}
    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }

    closeModal(){
        this.modalService.dismissAll();
    }

}