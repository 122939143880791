import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [

  {
    path: '',
    loadChildren: () =>
      import('./features/tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard], // Correct usage of guard class
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'notifications',
    loadChildren: () => import('./features/notifications/notifications.module').then((m) => m.NotificationsPageModule),
  },
  /** card */
  {
    path: 'refund-to-crypto',
    loadChildren: () =>
      import('./features/refund-to-crypto/refund-to-crypto.module').then(
        (m) => m.RefundToCryptoPageModule
      ),
  },
  {
    path: 'topup-from-crypto/:id',
    loadChildren: () =>
      import('./features/topup-from-crypto/topup-from-crypto.module').then(
        (m) => m.TopupFromCryptoPageModule
      ),
  },
  {
    path: 'card-history',
    loadChildren: () =>
      import('./features/card-history/card-history.module').then(
        (m) => m.CardHistoryPageModule
      ),
  },
  {
    path: 'card-registration/:id',
    loadChildren: () =>
      import('./features/card-registration/card-registration.module').then(
        (m) => m.CardRegistrationPageModule
      ),
  },
  {
    path: 'message-model',
    loadChildren: () =>
      import('./shared/components/message-model/message-model.module').then(
        (m) => m.MessageModelPageModule
      ),
  },
  {
    path: 'date-picker-modal',
    loadChildren: () =>
      import('./features/date-picker-modal/date-picker-modal.module').then(
        (m) => m.DatePickerModalPageModule
      ),
  },
  /** settings */
  {
    path: 'account-details/:id',
    loadChildren: () =>
      import('./features/account-details/account-details.module').then(
        (m) => m.AccountDetailsPageModule
      ),
  },
  {
    path: 'google-authentication',
    loadChildren: () => import('./features/google-authentication/google-authentication.module').then(m => m.GoogleAuthenticationPageModule),
  },

  {
    path: 'customer-support',
    loadChildren: () =>
      import('./features/customer-support/customer-support.module').then(
        (m) => m.CustomerSupportPageModule
      ),
  },
  {
    path: 'friend-lists',
    loadChildren: () =>
      import('./features/friend-lists/friend-lists.module').then(
        (m) => m.FriendListsPageModule
      ),
  },
  {
    path: 'add-friend',
    loadChildren: () =>
      import('./features/add-friend/add-friend.module').then(
        (m) => m.AddFriendPageModule
      ),
  },
  {
    path: 'new-ticket',
    loadChildren: () =>
      import('./features/new-ticket/new-ticket.module').then(
        (m) => m.NewTicketPageModule
      ),
  },
  {
    path: 'crypto-history',
    loadChildren: () =>
      import('./features/crypto-history/crypto-history.module').then(
        (m) => m.CryptoHistoryPageModule
      ),
  },
  {
    path: 'crypto-transfer',
    loadChildren: () =>
      import('./features/crypto-transfer/crypto-transfer.module').then(
        (m) => m.CryptoTransferPageModule
      ),
  },
  {
    path: 'crypto-history',
    loadChildren: () => import('./features/crypto-history/crypto-history.module').then(m => m.CryptoHistoryPageModule)
  },
  {
    path: 'crypto-receive',
    loadChildren: () => import('./features/crypto-receive/crypto-receive-routing.module').then(m => m.CryptoReceivePageRoutingModule)
  },
  {
    path: 'nft-transfer',
    loadChildren: () => import('./features/nft-transfer/nft-transfer.module').then(m => m.NftTransferPageModule)
  },
  {
    path: 'nft-recipient-address',
    loadChildren: () => import('./features/nft-transfer/nft-recipient-address/nft-recipient-address.module').then(m => m.NftRecipientAddressPageModule)
  },
  {
    path: 'nft-transfer-success',
    loadChildren: () => import('./features/nft-transfer/nft-transfer-success/nft-transfer-success.module').then(m => m.NftTransferSuccessPageModule)
  },
  {
    path: 'crypto-transfer-success',
    loadChildren: () =>
      import('./features/crypto-transfer/crypto-transfer-success/crypto-transfer-success.module').then(
        (m) => m.CryptoTransferSuccessPageModule
      ),
  },
  {
    path: 'refund-to-crypto-remainder',
    loadChildren: () => import('./features/refund-to-crypto/refund-to-crypto-remainder/refund-to-crypto-remainder.module').then(m => m.RefundToCryptoRemainderPageModule)
  },
  {
    path: 'refund-to-crypto-success',
    loadChildren: () => import('./features/refund-to-crypto/refund-to-crypto-success/refund-to-crypto-success.module').then(m => m.RefundToCryptoSuccessPageModule)
  },
  {
    path: 'top-up-crypto-remainder',
    loadChildren: () => import('./features/topup-from-crypto/top-up-crypto-remainder/top-up-crypto-remainder.module').then(m => m.TopUpCryptoRemainderPageModule)
  },
  {
    path: 'top-up-crypto-success',
    loadChildren: () => import('./features/topup-from-crypto/top-up-crypto-success/top-up-crypto-success.module').then(m => m.TopUpCryptoSuccessPageModule)
  },
  {
    path: 'swap',
    loadChildren: () => import('./features/swap/swap.module').then(m => m.SwapPageModule)
  },
  {
    path: 'exchange-offers',
    loadChildren: () => import('./features/swap/exchange-offers/exchange-offers.module').then(m => m.ExchangeOffersPageModule)
  },
  {
    path: 'swap-complete',
    loadChildren: () => import('./features/swap/swap-complete/swap-complete.module').then(m => m.SwapCompletePageModule)
  },
  {
    path: 'swap-history',
    loadChildren: () => import('./features/swap/swap-history/swap-history.module').then(m => m.SwapHistoryPageModule)
  },
  {
    path: 'edit-friend',
    loadChildren: () => import('./features/edit-friend/edit-friend.module').then( m => m.EditFriendPageModule)
  },
  {
    path: 'crypto-receive',
    loadChildren: () => import('./features/crypto-receive/crypto-receive.module').then(m => m.CryptoReceivePageModule)
  },
  {
    path: 'loan-detail',
    loadChildren: () => import('./features/shop/loan-detail/loan-detail.module').then(m => m.LoanDetailPageModule)
  },
  {
    path: '**',
    redirectTo: '',
  },






















];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
