import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

const API_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class AddFriendService {

  constructor(private httpClient: HttpClient) { }

  checkFriend(walletId: any): Observable<any> {
    return this.httpClient.get(API_URL + 'wallet/' + walletId + '?lang=EN')
  }

  addFriend(frWalletId: any, ownerWalletId: any) {

    const data = {
      friendWalletId: frWalletId,
      id: 0,
      ownerWalletId: ownerWalletId
    }

    return this.httpClient.post(API_URL + 'friend', data)
  }

  getFriendList(id: any) {
    return this.httpClient.get(API_URL + 'friend/all?limit=10&offset=1&ownerWalletId=' + id)
  }

  deleteFriend(id: any) {
    return this.httpClient.delete(API_URL + 'friend/' + id)
  }

  editFriend(id:any,data:any){
    return this.httpClient.put(API_URL + 'friend/' + id , data)
  }

  getFriendById(id:any){
    return this.httpClient.get(API_URL + 'friend/' + id)
  }
}
