<div class="background-image">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 top-third">
        <ion-list style="--background: transparent;" class="account-title">
          <ion-item style="--background: transparent;">
            <img class="close-btn" src="../../../../assets/icon/Icons/PNG/type=close.png" alt="" (click)="dismiss()">
            <ion-label aria-label="aid:your-friend" contentDescription="aid:your-friend">
              {{"FORM.your_friends" | translate}}</ion-label>
          </ion-item>
        </ion-list>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row" style="justify-content: center;">
      <div class="col-sm-6 col-md-8 col-lg-6">
        <form [formGroup]="searchForm" action="">
          <ion-grid>
            <ion-row>
              <ion-col size="9">
                <div class="search-box">
                  <img class="close-btn" src="../../../../assets/icon/Icons/PNG/type=search.png" alt="">
                  <input aria-label="aid:search-coin" contentDescription="aid:search-coin" type="text" formControlName="keyword" name="" id="" class="form-control search-input" placeholder="Search loan by id, name">
                </div>
              </ion-col>
              <ion-col size="3">
                <div class="cancel-btn">
                  <h5>{{"FORM.check" | translate}}</h5>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size-lg="12" size-md="9" size-sm="6">
                <div class="form-check form-check-inline status-check">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                  <label class="form-check-label" for="inlineCheckbox1">All</label>
                </div>
                <div class="form-check form-check-inline status-check">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                  <label class="form-check-label" for="inlineCheckbox2">Draft</label>
                </div>
                <div class="form-check form-check-inline status-check">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3">
                  <label class="form-check-label" for="inlineCheckbox3">Close</label>
                </div>
                <div style="padding-top: 12px;" class="form-check form-check-inline status-check">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox4" value="option4">
                  <label class="form-check-label" for="inlineCheckbox4">Active</label>
                </div>
                <div class="form-check form-check-inline status-check">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox5" value="option5">
                  <label class="form-check-label" for="inlineCheckbox5">Overdue</label>
                </div>
              </ion-col>
            </ion-row>
            <ion-row style="padding-top: 16px;">
              <ion-col>
                <h1 style="text-align: left;">Recently Searches</h1>
              </ion-col>
              <ion-col>
                <h1 style="text-align: right;"><span>Clear All</span></h1>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="11">
                <h2>264</h2>
              </ion-col>
              <ion-col>
                <img class="close-btn-one" src="../../../../assets/icon/Icons/PNG/type=close.png" alt="">
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="11">
                <h2>NFT Loan 1</h2>
              </ion-col>
              <ion-col>
                <img class="close-btn-one" src="../../../../assets/icon/Icons/PNG/type=close.png" alt="">
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="11">
                <h2>264 NFT Loan 2</h2>
              </ion-col>
              <ion-col>
                <img class="close-btn-one" src="../../../../assets/icon/Icons/PNG/type=close.png" alt="">
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </div>
    </div>
  </div>
</div>
