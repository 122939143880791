import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, IonicSafeString, ModalController, ToastController } from '@ionic/angular';
import { OtpAlertComponent } from '../otp-alert/otp-alert.component';
import { ToastMessageComponent } from '../toast-message/toast-message.component';

@Component({
  selector: 'app-security-keys',
  templateUrl: './security-keys.component.html',
  styleUrls: ['./security-keys.component.scss'],
})
export class SecurityKeysComponent {


  // @Input() showToastSecurityKey: boolean = false;
  showSuccessAlert = false;
  isAlertOpen = false;
  sercurityform!: FormGroup;
  @ViewChild(ToastMessageComponent) messageToast!: ToastMessageComponent;

  constructor(private fb: FormBuilder, private alertController: AlertController, private modalController: ModalController, private toastCtrl: ToastController) {
    this.sercurityform = this.fb.group({
      digit1: ['', [Validators.required, Validators.maxLength(1)]],
      digit2: ['', [Validators.required, Validators.maxLength(1)]],
      digit3: ['', [Validators.required, Validators.maxLength(1)]],
      digit4: ['', [Validators.required, Validators.maxLength(1)]]
    });
  }


  async presentNumericInputAlert() {
    const alert = await this.alertController.create({
      header: 'Please enter your Security PIN Number.',
      inputs: [
        {
          name: 'digit1', type: 'tel', attributes: { maxlength: 1 }, value: this.sercurityform.get('digit1')?.value,
        },
        {
          name: 'digit2', type: 'tel', attributes: { maxlength: 1 }, value: this.sercurityform.get('digit2')?.value,
        },
        {
          name: 'digit3', type: 'tel', attributes: { maxlength: 1 }, value: this.sercurityform.get('digit3')?.value,
        },
        {
          name: 'digit4', type: 'tel', attributes: { maxlength: 1 }, value: this.sercurityform.get('digit4')?.value,
        }
      ],
      buttons: [
        {
          text: 'Next',
          handler: (data) => {
            if (!this.isDigitValid(data.digit1) ||
              !this.isDigitValid(data.digit2) ||
              !this.isDigitValid(data.digit3) ||
              !this.isDigitValid(data.digit4)) {
              this.messageToast?.presentToast('Please fill in all 4 digits of the Security PIN.');


              return false; // Prevent closing the alert
            } else {
              this.sercurityform.patchValue({
                digit1: data.digit1,
                digit2: data.digit2,
                digit3: data.digit3,
                digit4: data.digit4,
              });
              this.openModal();
              return true; // Allow closing the alert
            }


          },
        },
      ],
    });

    await alert.present();

  }

  isDigitValid(digit: string): boolean {
    return digit && digit.length === 1 && /^\d$/.test(digit) as any;
  }

  async showValidationError() {
    // 'Please fill in all 4 digits of the Security PIN.'
    // const toast = await this.toastCtrl.create({
    //   message: 'Please fill in all 4 digits of the Security PIN.',
    //   duration: 2000,
    //   position: 'top',
    //   cssClass: 'toast-success'
    //   // color: status === 'fail' ? 'danger' : '#ffffff'
    // });
    // await toast.present();

  }



  setOpen(isOpen: boolean) {
    this.isAlertOpen = isOpen;
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: OtpAlertComponent,
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      // this.message = `Hello, ${data}!`;
    }
  }

  async presentAlert() {
    const messageHtml = `
      <div style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
        <div style="display: flex; align-items: center; justify-content: center;">
          <ion-icon name="checkmark-circle" style="color: green; font-size: 24px;"></ion-icon>
          <span style="margin-left: 8px; font-weight: bold;">Refund to Crypto Complete!</span>
        </div>
        <div style="text-align: center; margin-top: 8px;">
          Your balance in card is now topped-up successfully from your wallet's ETH.
        </div>
      </div>
    `;

    const alert = await this.alertController.create({
      message: new IonicSafeString(messageHtml),
      buttons: ['Okay'],
      cssClass: 'custom-alert'
    });

    await alert.present();
  }


  async openAlert() {
    const alert = await this.alertController.create({
      header: 'Enter OTP',
      inputs: [
        {
          name: 'otp',
          type: 'number',
          placeholder: 'OTP'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            // You can handle further logic here with the entered OTP
          }
        }
      ]
    });

    await alert.present();

    const { data, role } = await alert.onDidDismiss();
    if (role === 'confirm') {
      // Handle logic after confirmation with OTP
    }
  }

}
