import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { env } from 'process';
import { from, map, Observable } from 'rxjs';
// import { CountryData } from 'src/app/shared/models/country-data.interface';
import { SecureStorageService } from '../storage/secure-storage.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    token: any;
    constructor(
        private secureStorageService: SecureStorageService,
        private router: Router,
        private http: HttpClient
    ) { }

    generateRandomNo(): string {
        const randomNumber = Math.floor(1000000000 + Math.random() * 9000000000)
            .toString()
            .substring(0, 10);
        return randomNumber;
    }

    generateRandomNoAndString(): string {
        const randomNumber =
            'device' +
            Math.floor(1000000000 + Math.random() * 9000000000)
                .toString()
                .substring(0, 10);
        return randomNumber;
    }

    getDateTimeNow(): string {
        return formatDate(Date.now(), 'YYYYMMddHHmmssSSS', 'en', 'UTC');
    }

    isAuthenticated(): Observable<boolean> {
        return from(this.secureStorageService.getValue('session_token')).pipe(
            map(token => !!token)
        );
    }

    registerCheck(data: any): Observable<any> {
        return this.http.post(
            environment.apiUrl +
            'customer/register/check?' +
            'randomNumber=' +
            data.rNo +
            '&memberId=' +
            data.memberId,
            null
        );
    }

    verifyOTP(data: any): Observable<any> {
        return this.http.post(environment.apiUrl + 'member/verify/otp', data);
    }

    sendVerification(data: any, r_number: string): Observable<any> {
        return this.http.post(
            environment.apiUrl +
            'register/sendVerification?' +
            'randomNumber=' +
            r_number,
            data
        );
    }

    registerVerifyStatus(email: string, memberId: string, options?: { headers: HttpHeaders }): Observable<any> {
        return this.http.get(
            environment.apiUrl +
            'customer/register/verify/status?' +
            'email=' +
            email +
            '&memberId=' +
            memberId
        );
    }

    register(data: any): Observable<any> {
        return this.http.post(environment.apiUrl + 'customer/register', data);
    }

    signIn(data: any, r_no: any): Observable<any> {
        return this.http.post(environment.apiUrl + 'customer/login?' + 'randomNumber=' + r_no, data);
    }

    getKeyInfo(data: any): Observable<any> {
        return this.http.post(environment.apiUrl + 'customer/keyinfo', data);
    }
    emailCheck(email: string, r_number: string) {
        return this.http.get(environment.apiUrl + 'customer/email/check?' + 'email=' + email + '&randomNumber=' + r_number);
    }

    emailKeyInfo(email: string) {
        return this.http.get(environment.apiUrl + 'customer/email/keyinfo?' + 'email=' + email);
    }

    getSignInOTP(email: string, emailEn: string, requestToken: string): Observable<any> {
        return this.http.post(environment.apiUrl + 'customer/login/otp?' + 'email=' + email + '&emailLock=' + emailEn + '&otpRequestToken=' + requestToken, null);
    }

    sendEmailOTP(data: any): Observable<any> {
        return this.http.post(environment.apiUrl + 'member/send/otp', data);
    }

    verifySignInOTP(email: string, emailEn: string, otp: string, verifyToken: string , deviceId : string, deviceType : string ,ip : string): Observable<any> {
        return this.http.post(environment.apiUrl + 'customer/login/verify/otp?' + 'appVersion=' + this.generateRandomNoAndString() + '&deviceId=' + deviceId + '&email=' + email + '&emailLock=' + emailEn + '&deviceType=' + deviceType + '&ip=' + ip + '&otp=' + otp + '&verifyToken=' + verifyToken, null);
    }

    /**for card register*/

    postCardRegisterOtp(data: any): Observable<any> {
        return this.http.post(environment.apiUrl + 'customer/verify/otp', data);
    }

    sendOtpCardRegistration(
        email: string,
        walletId: string,
        verifyOTP: string
    ): Observable<any> {
        return this.http.get(
            environment.apiUrl +
            'customer/send/otp?' +
            'email=' +
            email +
            '&verifyToken=' +
            verifyOTP +
            '&walletId=' +
            walletId
        );
    }

    getOtpForCardRegistration(email: string, walletId: string): Observable<any> {
        return this.http.get(
            environment.apiUrl +
            'customer/request/otp?' +
            'email=' +
            email +
            '&walletId=' +
            walletId
        );
    }

    customerRegisterRequest(data: any): Observable<any> {
        return this.http.post(
            environment.apiUrl + 'customer/register/request',
            data
        );
    }

    getForgotPasswordOTP(forgotEmail: string): Observable<any> {
        return this.http.get(environment.apiUrl + 'request/otp?' + 'email=' + forgotEmail);
    }

    sendForgotPasswordOTP(forgotEmail: string, token: string): Observable<any> {
        return this.http.post(environment.apiUrl + 'send/otp?' + 'email=' + forgotEmail + '&verifyToken=' + token, null);
    }

    verifyForgotPasswordOTP(data: any): Observable<any> {
        return this.http.post(environment.apiUrl + 'verify/otp', data);
    }

    resetPassword(data: any): Observable<any> {
        return this.http.post(environment.apiUrl + 'forgot/password', data);
    }

    updateFirebaseToken(token: string): Observable<any> {
        return this.http.post(environment.apiUrl + 'firebase/token?firebaseToken=' + token, null);
    }

}
