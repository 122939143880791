<div class="custom-toast" [class.show]="showToast">
  <div class="toast-content">
    <div class="toast-icon">
      <img src="../../../../assets/icon/Icons/SVG/type=alert_warning.svg" alt="">

    </div>
    <div class="toast-message">
      <h4>{{ toastTitle }}</h4>
      <p>{{ toastMessage }}</p>
    </div>
  </div>
  <ion-item class="ion-okay" lines="none">
    <ion-button fill="clear" aria-label="aid:=dimiss_toast-btn" contentDescription="aid:=dimiss_toast-btn" (click)="dismissToast()">Okay</ion-button>
  </ion-item>
</div>