import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
})
export class ToastMessageComponent {


  @Input() showToast: boolean = false;
  @Input() showToastOtp: boolean = false;
  @Input() toastMessage: string = '';
  @Input() toastTitle: string = '';
  // @Output() dismiss = new EventEmitter<void>();

  dismissToast() {
    this.showToast = false;
  }

  constructor(private toastController: ToastController) { }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 3000,
      position: 'top',
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Dismiss clicked');
          }
        }
      ],
      cssClass: 'custom-toast-Otp'
    });

    await toast.present();
  }
}
