
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { tap } from 'rxjs/operators';
import { mergeMapTo } from 'rxjs/internal/operators/mergeMapTo';
import { AlertController, IonicSafeString } from '@ionic/angular';
import { SecureStorageService } from '../storage/secure-storage.service';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { NotificationModel } from 'src/app/shared/models/notification.interface';
import { getToken } from 'firebase/app-check';

@Injectable({
  providedIn: 'root'
})
export class FirebaseServiceService {

  currentMessage = new BehaviorSubject<NotificationModel | null>(null);
  notificationCount = new BehaviorSubject<number>(0);
  notifications = new BehaviorSubject<NotificationModel[]>([]);

  currentToken: string | null = null;

  vk: string = 'BAHJvI1cMTMsJUDVDr1TZuKTp0YFrwVY0ytwk3iTKcafuRTS5XEFDPBl0A-pR49Aqw7o1SON2vjc8g-Y_XO2hio';


  constructor(private authService: AuthService, private afMessaging: AngularFireMessaging, private secureStorageService: SecureStorageService, private alertCtrl: AlertController) {
    console.log('firebase.apps.length ', firebase.apps.length);
    if (firebase.apps.length === 0) {
      console.log('initialize firebase')
      firebase.initializeApp(environment.firebase);
    }

    this.afMessaging.onMessage(payload => {
      console.log('Message received. ', payload);
      // Handle your message here
    });
  }


  async requestPermission() {
    const browser = this.detectBrowserName();
    console.log('browser >>>>>>', browser);
    try {
      const currentPermission = Notification.permission;
      if (browser === 'safari') {
        console.log('currentPermission >>>> ', currentPermission);
        if (currentPermission === 'granted') {
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistration().then(function (registration) {
              if (registration) {
                console.log('Service Worker is registered:', registration);
              } else {
                console.log('No Service Worker is registered.');
              }
            }).catch(function (error) {
              console.log('Error checking Service Worker registration:', error);
            });
          } else {
            console.log('Service Workers are not supported by this browser.');
          }
        } else {
          const alert = await this.alertCtrl.create({
            cssClass: 'theme-alert confirm-alert',
            header: 'Allow notification permission',
            message: 'Please allow notification permission',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'cancel-btn',
                handler: () => {
                  console.log('Submission canceled');
                },
              },
              {
                text: 'Allow',
                cssClass: 'submit-btn',
                handler: () => {
                  console.log('request ios permission');
                  this.reqIOSPermission();
                },
              },
            ],
          });
          await alert.present();
        }

      } else {
        this.reqPermission();
      }

    } catch (error) {
      const alert = await this.alertCtrl.create({
        cssClass: 'theme-alert confirm-alert',
        header: 'Allow notification permission',
        message: 'Please allow notification permission',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'cancel-btn',
            handler: () => {
              console.log('Submission canceled');
            },
          },
          {
            text: 'Allow',
            cssClass: 'submit-btn',
            handler: () => {
              console.log('request ios permission');
              this.reqPermission();
            },
          },
        ],
      });
      await alert.present();
    }
  }

  reqIOSPermission() {
    if ('Notification' in window) {
      Notification.requestPermission().then(async permission => {
        console.log('permission 1 >>>>> ', permission);
        if (permission === 'granted') {
          console.log('sevice worker', 'serviceWorker' in navigator);
          const registration = await this.registerServiceWorker();
          // console.log('registration 1 >>>>> ', registration);
          // this.getNewFirebaseToken(messaging);
          console.log('environment.firebase.vapidKey >>>>>>> ', environment.firebase.vapidKey);
          const currentToken = await this.secureStorageService.getValue('firebaseToken');
          console.log('currentToken >>>>> ', currentToken);
          const newToken = await firebase.messaging().getToken({
            vapidKey: environment.firebase.vapidKey,
            serviceWorkerRegistration: registration,
          });
          if (newToken) {
            console.log('firebaseToken >>>>>> ', newToken);
            await this.secureStorageService.setValue('firebaseToken', newToken);
            this.listenForMessages();
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        } else {
          console.log('Notification permission denied.');
        }
      });
    } else {
      console.log('Notifications are not supported by this browser.');
    }
    // Notification.requestPermission().then(permission => {
    //   console.log('permission >>>>>>>>>> ', permission);
    //   if (permission === 'granted') {
    //     console.log('Notification permission granted.');
    //     // You can now send notifications
    //   } else if (permission === 'denied') {
    //     console.log('Notification permission denied.');
    //   } else {
    //     console.log('Notification permission dismissed.');
    //   }
    // });
  }

  reqPermission() {
    this.afMessaging.requestPermission
      // .pipe()
      .subscribe({
        next: async (res) => {
          console.log('afmessaging resss >>>>> ', res);
          if (res === 'granted') {
            const registration = await this.registerServiceWorker();
            console.log('registration >>>>> ', registration);
            const newToken = await firebase.messaging().getToken({
              vapidKey: this.vk,
              serviceWorkerRegistration: registration,
            });
            console.log('newToken >>>>> ', newToken);
            if (newToken) {
              await this.secureStorageService.setValue('firebaseToken', newToken);
              this.listenForMessages();
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          }
        },
        error: (error) => {
          console.log('Permission not granted >>>>>>>>>>>>>>>>>>>>>>> ', error);
        }
      }
      );
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    console.log('agent >>>>>>', agent);
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  testRequestPFS() {
    return this.afMessaging.getToken;
  }

  async useWorker(ss: any): Promise<any> {
    // return this.afMessaging.useServiceWorker(serviceWorker)
  }

  async getFirebaseToken() {
    const permissionGranted = await this.requestNotificationPermission();
    if (!permissionGranted) {
      console.log('Notification permission not granted. Cannot get Firebase token.');
      await this.showPermissionAlert();
      return;
    }

    try {
      const messaging = firebase.messaging();
      // Retrieve the current token from secure storage
      const currentToken = await this.secureStorageService.getValue('firebaseToken');
      if (currentToken) {
        try {
          console.log('Old Firebase Token:', currentToken);
          // Attempt to delete the existing token
          this.afMessaging.deleteToken(currentToken);
          // await messaging.deleteToken();
          this.getNewFirebaseToken(messaging);
        } catch (error: any) {
          // Handle the case where the token might not exist anymore
          this.getNewFirebaseToken(messaging);
          if (error === 'messaging/token-unsubscribe-failed' || error.customData.errorInfo.includes('Request entity was not found') || error.code.includes('messaging/token-unsubscribe-failed')) {
            console.log('Token not found or already deleted:', error);
          } else {
            console.error('Failed to delete old Firebase token:', error);
            throw error; // Re-throw if it's a different error
          }
        }
      } else {
        // Generate a new token
        this.getNewFirebaseToken(messaging);
      }
    } catch (error) {
      console.error('Error retrieving token:', error);
    }
  }

  async getNewFirebaseToken(_messaging: any) {
    const registration = await this.registerServiceWorker();
    try {
      const newToken = await _messaging.getToken({
        vapidKey: this.vk,
        serviceWorkerRegistration: registration,
      });

      console.log('new token >>>', newToken);
      if (newToken) {
        await this.secureStorageService.setValue('firebaseToken', newToken);
        this.listenForMessages();
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (error) {
      this.requestPermission();
    }

  }

  async requestNotificationPermission(): Promise<boolean> {
    try {
      const permission = await Notification.requestPermission();
      return permission === 'granted';
    } catch (error) {
      console.error('Error requesting notification permission:', error);
      return false;
    }
  }

  async registerServiceWorker(): Promise<ServiceWorkerRegistration> {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        await navigator.serviceWorker.ready;
        return registration;
      } catch (error) {
        console.error('Service Worker registration failed:', error);
        throw error;
      }
    } else {
      console.warn('Service Worker is not supported in this browser.');
      throw new Error('Service Worker not supported');
    }
  }



  async showPermissionAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Warning',
      message: new IonicSafeString(`
        <div style="display: flex; align-items: center;">
          <ion-icon name="warning" style="color: red; font-size: 24px; margin-right: 10px;"></ion-icon>
          <span>Please allow your browser notification permission</span>
        </div>
      `),
      buttons: ['OK']
    });
    await alert.present();
  }

  deleteToken() {
    if (this.currentToken) {
      this.afMessaging.deleteToken(this.currentToken)
        .pipe(
          tap(() => {
            this.currentToken = null;
          })
        )
        .subscribe();
    }
  }

  /** notification list */

  // Listen for incoming messages
  listenForMessages() {
    this.afMessaging.messages.subscribe(async (payload: any) => {
      console.log('Foreground message received >> ', payload);
      // window.location = payload.fcmOptions.link;
      const newNotification: NotificationModel = {
        id: payload.messageId || Date.now().toString(), // Use messageId or generate a timestamp-based ID
        title: payload.notification?.title || 'No title',
        body: payload.notification?.body || 'No body',
        read: false,
        timestamp: new Date()
      };
      console.log('newNotification >> ', newNotification);
      const cid = await this.secureStorageService.getValue('customer_id');
      await this.addNotification(newNotification, cid!); // Save the notification
      await this.updateNotificationCount(cid!); // Update the count correctly
      this.currentMessage.next(newNotification); // Update the BehaviorSubject

    });
    this.loadNotificationsAndCount();
  }

  async addNotification(notification: NotificationModel, id: string) {
    console.log('noti >>>>> ', notification);
    console.log('id >>> ', id);
    const notifications = await this.loadNotificationsFromStorage(id);
    notifications.push(notification);
    this.sortNotificationsByTimestamp(notifications);
    await this.saveNotificationsToStorage(id, notifications);
  }

  async markAsRead(id: string, notification: NotificationModel) {
    const notifications = await this.loadNotificationsFromStorage(id);
    const index = notifications.findIndex(n => n.id === notification.id);
    if (index > -1) {
      notifications[index].read = true;
      this.sortNotificationsByTimestamp(notifications);
      await this.saveNotificationsToStorage(id, notifications);
      this.updateNotificationCount(id); // Update count when marking as read
    }
  }

  async saveNotificationsToStorage(userId: string, notifications: NotificationModel[]) {
    const key = `notifications_${userId}`; // Dynamic key for each user
    await this.secureStorageService.setValue(key, JSON.stringify(notifications));
    this.updateNotificationCount(userId);
  }

  async loadNotificationsFromStorage(userId: string): Promise<NotificationModel[]> {
    const key = `notifications_${userId}`;
    console.log('key >>>>>>> ', key);
    const notificationsString = await this.secureStorageService.getValue(key);
    const notifications = notificationsString ? JSON.parse(notificationsString) : [];
    this.sortNotificationsByTimestamp(notifications);
    return notifications;
  }

  async updateNotificationCount(userId: string) {
    const notifications = await this.loadNotificationsFromStorage(userId);
    const unreadCount = notifications.filter(n => !n.read).length;
    this.notificationCount.next(unreadCount);
  }

  async loadNotificationsAndCount() {
    console.log('loadNotificationsAndCount >>>>>>> ');
    const cid = await this.secureStorageService.getValue('customer_id');
    console.log('loadNotificationsAndCount cid >>>>>>> ', cid);
    if (cid) {
      const notifications = await this.loadNotificationsFromStorage(cid);
      const unreadCount = notifications.filter(n => !n.read).length;
      this.notificationCount.next(unreadCount);
    }
  }

  async resetNotificationCount() {
    this.notificationCount.next(0);
    await this.secureStorageService.setValue('notificationCount', '0');
  }

  private sortNotificationsByTimestamp(notifications: NotificationModel[]) {
    // Sort notifications by timestamp in descending order
    notifications.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  }

  // New Method: Mark All Notifications as Read
  async markAllAsRead(id: string) {
    const notifications = await this.loadNotificationsFromStorage(id);
    notifications.forEach(notification => notification.read = true);
    await this.saveNotificationsToStorage(id, notifications);
    this.updateNotificationCount(id); // Reset the count as all notifications are now read
  }

  async clearNotificationsOnLogout() {
    this.notificationCount.next(0);  // Reset count
    // await this.secureStorageService.clear();  // Clear all stored notifications
  }
}

