import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-email-tool-tips',
    templateUrl: './security-key-success.component.html',
    styleUrls: ['./security-key-success.component.scss'],
})
export class SecurityKeySuccessComponent implements OnInit {

    @Input() message?: string;
    @Input() title?: string;
    @Input() type?: string;

    constructor(private modalService: NgbActiveModal, private navCtrl: NavController) { }
    ngOnInit() {
    }

    closeModal() {
        this.modalService.close('close');
        if (this.type == 'FP') {
            this.navCtrl.navigateRoot(['auth/sign-in']);
        }
    }

}