import { Injectable } from '@angular/core';
import {
  AlertController,
  IonicSafeString,
  ToastController,
} from '@ionic/angular';
import * as forge from 'node-forge';
import { Clipboard } from '@capacitor/clipboard';
import * as CryptoJS from 'crypto-js';
import { LoadingService } from '../helper/loading.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SecureStorageService } from '../storage/secure-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PkPukDecryptEncryptoService {
  private readonly iterationCount = 1000;
  private readonly keySize = 256 / 32; // 256 bits / 32 (Word size)
  private readonly saltSize = 16; // 128 bits
  private readonly ivSize = 16; // 128 bits

  sercurityform!: FormGroup;

  passwordValidations = [
    { message: 'Minimum 8 characters', valid: false },
    { message: 'Maximum 50 characters', valid: false },
    { message: 'At least one uppercase letter', valid: false },
    { message: 'At least one number', valid: false },
    { message: 'At least one special character (!@#$%^&*)', valid: false },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private alertCtrl: AlertController,
    private helper: LoadingService,
    private router: Router,
    private secureStorageService: SecureStorageService
  ) {
    this.sercurityform = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          Validators.pattern('^(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*]).+$'),
        ],
      ],
    });

    this.sercurityform.controls['password'].valueChanges.subscribe((value) => {
      this.updateValidationStatus(value);
    });
  }

  updateValidationStatus(value: string) {
    this.passwordValidations[0].valid = value.length >= 8 && value.length <= 50;
    this.passwordValidations[1].valid = /[A-Z]/.test(value);
    this.passwordValidations[2].valid = /\d/.test(value);
    this.passwordValidations[3].valid = /[!@#$%^&*(),.?":{}|<>]/.test(value);
  }

  isPasswordValid(password: string): boolean {
    return (
      this.passwordValidations[0].valid &&
      this.passwordValidations[1].valid &&
      this.passwordValidations[2].valid &&
      this.passwordValidations[3].valid
    );
  }

  encryptUserEmail(dpk: string, toEncrypt: string): string {
    const publicKey = forge.pki.publicKeyFromPem(
      `-----BEGIN PUBLIC KEY-----${dpk}-----END PUBLIC KEY-----`
    );
    const encryptedWithPublicKeyByte = publicKey.encrypt(
      toEncrypt,
      'RSAES-PKCS1-V1_5'
    );
    const encryptedBase64 = forge.util.encode64(encryptedWithPublicKeyByte);
    return encryptedBase64;
  }

  async generateKeyPairForEmailPasswordEncryptDecrypt(
    textToEncrypt: string,
    type: string
  ) {
    const unformattedPublicKeyPem = await this.secureStorageService.getValue(
      'unformattedPublicKeyPem'
    );

    // const privateKey = forge.pki.privateKeyFromPem(unformattedPrivateKeyPem);
    const publicKey = forge.pki.publicKeyFromPem(unformattedPublicKeyPem!);

    const encryptedWithPublicKeyByte = publicKey.encrypt(
      textToEncrypt,
      'RSAES-PKCS1-V1_5'
    );

    const encryptedBase64 = forge.util.encode64(encryptedWithPublicKeyByte);

    if (type === 'email') {
      await this.secureStorageService.setValue(
        'encryptedEmail',
        encryptedBase64
      );
    } else if (type === 'password') {
      await this.secureStorageService.setValue(
        'encryptedPassword',
        encryptedBase64
      );
    }

    return encryptedBase64;
  }

  async generateKeyPair() {
    const keypair = forge.pki.rsa.generateKeyPair(2048);
    const publicKeyPem = forge.pki.publicKeyToPem(keypair.publicKey);
    const privateKeyPem = forge.pki.privateKeyToPem(keypair.privateKey);

    await this.secureStorageService.setValue(
      'unformattedPrivateKeyPem',
      privateKeyPem
    );
    await this.secureStorageService.setValue(
      'unformattedPublicKeyPem',
      publicKeyPem
    );

    const formattedPrivateKeyPem = this.formatPrivateKey(privateKeyPem);
    const formattedPublicKeyPem = this.formatPublicKey(publicKeyPem);

    await this.secureStorageService.setValue(
      'formattedPrivateKeyPem',
      formattedPrivateKeyPem
    );
    await this.secureStorageService.setValue(
      'formattedPublicKeyPem',
      formattedPublicKeyPem
    );

    return {
      publicKey: formattedPublicKeyPem,
      privateKey: formattedPrivateKeyPem,
    };
  }

  stringToBase64(input: string): string {
    return btoa(input);
  }

  formatPrivateKey(privateKey: string): string {
    // Remove the header, footer, and newline characters
    return privateKey
      .replace('-----BEGIN RSA PRIVATE KEY-----', '')
      .replace('-----END RSA PRIVATE KEY-----', '')
      .replace(/\s+/g, '');
  }

  formatPublicKey(publicKey: string): string {
    // Remove the header, footer, and newline characters
    return publicKey
      .replace('-----BEGIN PUBLIC KEY-----', '')
      .replace('-----END PUBLIC KEY-----', '')
      .replace(/\s+/g, '');
  }

  async encryptPassword(password: string): Promise<string> {
    const { encryptedPublicKey, iv, salt, securityPin } =
      await this.getStoredEncryptedPrivateKey();
    const originalPublicKey = this.decryptPublicKey(
      encryptedPublicKey,
      iv,
      salt,
      securityPin
    );
    try {
      const publicKeyObj = forge.pki.publicKeyFromPem(`${originalPublicKey}`); //RSA-OAEP
      let encryptedMessage = publicKeyObj.encrypt(
        password,
        'RSAES-PKCS1-V1_5',
        {
          md: forge.md.sha256.create(),
          mgf1: forge.mgf.mgf1.create(forge.md.sha1.create()),
        }
      );
      return forge.util.encode64(encryptedMessage);
    } catch (error) {
      throw error;
    }
  }

  encryptPublicKey(
    plainText: string,
    apiSecret: string
  ): { encryptedData: string; iv: string; salt: string } {
    const salt = CryptoJS.lib.WordArray.random(this.saltSize).toString(
      CryptoJS.enc.Hex
    );
    const iv = CryptoJS.lib.WordArray.random(this.ivSize).toString(
      CryptoJS.enc.Hex
    );
    const key = this.generateKey(salt, apiSecret);

    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return {
      // encryptedData: CryptoJS.enc.Base64.parse(encrypted)
      encryptedData: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
      iv: iv,
      salt: salt,
    };
  }

  decryptPublicKey(
    encryptedData: string,
    iv: string,
    salt: string,
    apiSecret: string
  ): string {
    try {
      const key = this.generateKey(salt, apiSecret);
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(encryptedData),
      });
      const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
        iv: CryptoJS.enc.Hex.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const decryptedMessage = decrypted.toString(CryptoJS.enc.Utf8);
      if (!decryptedMessage) {
        // this.helper.presentToast('Possible wrong security key');
        throw new Error('Decryption failed. Possible wrong security key.');
      }
      return decryptedMessage;
    } catch (error) {
      // this.helper.presentToast('Possible wrong security key')
      throw new Error('Decryption failed. Possible wrong security key.');
    }
  }

  // use cprkKey to encrypt for sign in

  private generateKey(salt: string, apiSecret: string): CryptoJS.lib.WordArray {
    const key = CryptoJS.PBKDF2(apiSecret, CryptoJS.enc.Hex.parse(salt), {
      keySize: this.keySize,
      iterations: this.iterationCount,
    });
    return key;
  }

  async storeEncryptedPublicKey(
    encryptedPublicKey: string,
    iv: string,
    salt: string
  ) {
    await this.secureStorageService.setValue('cprkKey', encryptedPublicKey);
    await this.secureStorageService.setValue('iv', iv);
    await this.secureStorageService.setValue('salt', salt);
  }

  async storedInfoForCardRegistration(
    customerId: string,
    walletId: string,
    email: string
  ) {
    await this.secureStorageService.setValue('customer_id', customerId);
    await this.secureStorageService.setValue('wallet_id', walletId);
    await this.secureStorageService.setValue('email', email);
  }
  async storeRequestTokenDataResponse(token: string) {
    await this.secureStorageService.setValue('verify_token', token);
  }
  async storeFirebaseToken(firebaseToken: string) {
    await this.secureStorageService.setValue('firebaseToken', firebaseToken);
  }

  async getStoredEncryptedPrivateKey() {
    const encryptedPublicKey = await this.secureStorageService.getValue(
      'cprkKey'
    );
    const iv = await this.secureStorageService.getValue('iv');
    const salt = await this.secureStorageService.getValue('salt');
    const securityPin = await this.secureStorageService.getValue(
      'security_pin'
    );

    return {
      encryptedPublicKey: encryptedPublicKey!,
      iv: iv!,
      salt: salt!,
      securityPin: securityPin!,
    };
  }

  /** Can use in future, alert box with 4 number digits */

  // async firstSecurityAlert(message: string, type: string): Promise<any> {
  //   const alert = await this.alertCtrl.create({
  //     cssClass: 'security-alert',
  //     message: message,
  //     inputs: [
  //       {
  //         name: 'digit1',
  //         type: 'number',
  //         attributes: { maxlength: 1 },
  //       },
  //       {
  //         name: 'digit2',
  //         type: 'number',
  //         attributes: { maxlength: 1 },
  //       },
  //       {
  //         name: 'digit3',
  //         type: 'number',
  //         attributes: { maxlength: 1 },
  //       },
  //       {
  //         name: 'digit4',
  //         type: 'number',
  //         attributes: { maxlength: 1 },
  //       },
  //     ],
  //     buttons: [
  //       {
  //         text: type === 'register' ? 'Cancel' : 'Copy',
  //         handler: (data) => {
  //           if (type === 'register') {
  //             // return true;
  //             // alert.dismiss();
  //             this.router.navigate(['/sign-in']);
  //             alert.dismiss();
  //           } else if (type === 'signin') {
  //             const inputs = document.querySelectorAll('ion-alert input');
  //             let allFilled = true;
  //             inputs.forEach((input) => {
  //               if ((input as HTMLInputElement).value.trim() === '') {
  //                 allFilled = false;
  //                 this.helper.presentToast('Fill all the keys!');
  //               }
  //             });
  //             const securityKey = `${data.digit1}${data.digit2}${data.digit3}${data.digit4}`;
  //             console.log('securityKey, ', securityKey);
  //             if (allFilled) {
  //               this.copyToClipboard(securityKey);
  //             }
  //             return false; // Keep the alert open
  //           }
  //           return false;
  //         },
  //       },
  //       {
  //         text: type === 'register' ? 'Set' : 'Confirm',
  //         handler: (data) => {
  //           if (type === 'register') {
  //             const securityKey = `${data.digit1}${data.digit2}${data.digit3}${data.digit4}`;
  //             console.log('Security Key:', securityKey);
  //             return true;
  //           } else if (type === 'signin') {
  //             return true;
  //           }
  //           return;
  //         },
  //       },
  //     ],
  //     backdropDismiss: false,
  //   });
  //   await alert.present();
  //   // Automatically jump to the next input field
  //   const inputs = document.querySelectorAll('ion-alert input');
  //   if (inputs.length > 0) {
  //     (inputs[0] as HTMLInputElement).focus();
  //   }
  //   inputs.forEach((input, index) => {
  //     input.addEventListener('input', () => {
  //       const inputElement = input as HTMLInputElement;
  //       if (inputElement.value.length > 1) {
  //         inputElement.value = inputElement.value.charAt(0);
  //       }
  //       if (inputElement.value.length === 1 && index < inputs.length - 1) {
  //         (inputs[index + 1] as HTMLInputElement).focus();
  //       }
  //     });
  //     input.addEventListener('keydown', (event) => {
  //       const keyboardEvent = event as KeyboardEvent;
  //       if (
  //         keyboardEvent.key === 'Backspace' &&
  //         index > 0 &&
  //         (input as HTMLInputElement).value.length === 0
  //       ) {
  //         (inputs[index - 1] as HTMLInputElement).focus();
  //       }
  //     });
  //   });

  //   const result = await alert.onDidDismiss();
  //   const securityKey = result.data.values
  //     ? `${result.data.values.digit1}${result.data.values.digit2}${result.data.values.digit3}${result.data.values.digit4}`
  //     : null;
  //   return securityKey;
  // }

  // async secondSecurityAlert(firstKey: string, type: string): Promise<any> {
  //   const alert = await this.alertCtrl.create({
  //     cssClass: 'security-alert',
  //     message: 'Please confirm your security key',
  //     inputs: [
  //       { name: 'digit1', type: 'number', attributes: { maxlength: 1 } },
  //       { name: 'digit2', type: 'number', attributes: { maxlength: 1 } },
  //       { name: 'digit3', type: 'number', attributes: { maxlength: 1 } },
  //       { name: 'digit4', type: 'number', attributes: { maxlength: 1 } },
  //     ],
  //     buttons: [
  //       {
  //         text: 'Copy',
  //         handler: () => {
  //           const inputs = document.querySelectorAll('ion-alert input');
  //           let allFilled = true;
  //           inputs.forEach((input) => {
  //             if ((input as HTMLInputElement).value.trim() === '') {
  //               allFilled = false;
  //               this.helper.presentToast('Fill all the keys!');
  //             }
  //           });
  //           if (allFilled) {
  //             this.copyToClipboard(firstKey);
  //           }
  //           return false; // Keep the alert open
  //         },
  //       },
  //       {
  //         text: 'Confirm',
  //         handler: (data) => {
  //           const confirmedKey = `${data.digit1}${data.digit2}${data.digit3}${data.digit4}`;
  //           if (firstKey === confirmedKey) {
  //             console.log('Keys match!');
  //             return true; // Dismiss the alert
  //           } else {
  //             this.helper.presentToast('Keys do not match!');
  //             return false; // Keep the alert open
  //           }
  //         },
  //       },
  //     ],
  //     backdropDismiss: false,
  //   });
  //   await alert.present();
  //   // Automatically jump to the next input field
  //   const inputs = document.querySelectorAll('ion-alert input');

  //   if (inputs.length > 0) {
  //     (inputs[0] as HTMLInputElement).focus();
  //   }

  //   inputs.forEach((input, index) => {
  //     input.addEventListener('input', () => {
  //       const inputElement = input as HTMLInputElement;
  //       if (inputElement.value.length > 1) {
  //         inputElement.value = inputElement.value.charAt(0);
  //       }
  //       if (inputElement.value.length === 1 && index < inputs.length - 1) {
  //         (inputs[index + 1] as HTMLInputElement).focus();
  //       }
  //     });
  //     input.addEventListener('keydown', (event) => {
  //       const keyboardEvent = event as KeyboardEvent;
  //       if (
  //         keyboardEvent.key === 'Backspace' &&
  //         index > 0 &&
  //         (input as HTMLInputElement).value.length === 0
  //       ) {
  //         (inputs[index - 1] as HTMLInputElement).focus();
  //       }
  //     });
  //   });

  //   const result = await alert.onDidDismiss();
  //   const confirmed = result.data.values;
  //   return confirmed;
  // }

  async copyToClipboard(text: string): Promise<void> {
    try {
      await Clipboard.write({
        string: text,
      });
      this.helper.presentToast('Text copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy text: ', err);
      this.helper.presentToast('Failed to copy text to clipboard.');
    }
  }
}
