import { Component, Input, OnInit } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { ToastController } from '@ionic/angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit {

  selectedDateRange!: DateRange<Date>;
  @Input() type: any;
  startDate: Date | null = null;
  endDate: Date | null = null;
  toast: boolean = false;
  constructor(public modal: NgbActiveModal, private toastController: ToastController) { }

  ngOnInit() {
  }


  cancel() {
    this.modal.close({
      type: this.type
    })
  }


  async apply() {
    if (this.endDate == null) {
      this.toast = true;
    }
    else {
      this.toast = false;
      this.modal.close({
        startDate: this.startDate,
        endDate: this.endDate,
        type: this.type
      });
    }
  }

  _onSelectedChange(date: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date
      );
      this.startDate = this.selectedDateRange.start;
      this.endDate = this.selectedDateRange.end;
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }
  }
}
