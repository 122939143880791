import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin, from, of, throwError } from 'rxjs';
import {
  catchError,
  delay,
  finalize,
  map,
  mergeMap,
  retryWhen,
  switchMap,
  take,
  tap,
  timeout,
} from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { formatDate, Location } from '@angular/common';
import { AuthService } from './auth.service';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { SecureStorageService } from '../storage/secure-storage.service';
import { LoadingService } from '../helper/loading.service';
import {
  AlertController,
  IonicSafeString,
  NavController,
} from '@ionic/angular';
import { error } from 'console';
import { environment } from 'src/environments/environment.prod';
import { FirebaseServiceService } from '../firebase-service/firebase-service.service';

interface Tokens {
  [key: string]: any; // Define the structure of your tokens object
}

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
  authenticationToken: any = '';

  private ongoingRequests = 0;

  constructor(
    private authService: AuthService,
    private secureStorageService: SecureStorageService,
    private helper: LoadingService,
    private location: Location,
    private httpClient: HttpClient,
    private navCtrl: NavController,
    private firebaseService: FirebaseServiceService
  ) { }

  /** for use later */
  async getToken(key: string): Promise<string> {
    const token = await this.secureStorageService.getValue(key);
    if (token) return token;
    return '';
  }

  refreshToken(
    deviceId: string,
    refreshToken: string,
    userId: string
  ): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          // observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      // Constant.authenticationToken = ""
      this.httpClient
        .get(environment.apiUrl + 'refreshToken/jwt/token', {
          params: {
            deviceId: deviceId,
            refreshToken: refreshToken,
            userId: userId,
          },
        })
        .subscribe(
          (res: any) => {
            this.secureStorageService.setValue('session_token', res.data);
            this.refreshTokenInProgress = false;
            this.tokenRefreshedSource.next(res.data);
          },
          (err) => {
            this.refreshTokenInProgress = false;
            this.cleanCache().then(async () => {
              this.navCtrl.navigateRoot('auth/sign-in');
            });
          }
        );
      return EMPTY;
    }
  }

  addAuthHeader(request: HttpRequest<any>, tokens?: any): HttpRequest<any> {
    const { t_token_0, t_token_1, t_token_2 } = tokens;

    if (t_token_0) {
      request = request.clone({
        headers: request.headers.set(
          'Temp-Authorization',
          'Bearer ' + t_token_0
        ),
      });
    }

    if (t_token_1) {
      request = request.clone({
        headers: request.headers.set('Random-Number', t_token_1),
      });
    }
    if (t_token_2) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + t_token_2),
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    let transactionId =
      this.authService.getDateTimeNow() +
      '-' +
      this.authService.generateRandomNo();
    request = request.clone({
      headers: request.headers.set('Transaction-Id', transactionId),
    });

    if (
      request.url.includes('customer/register/check') ||
      request.url.includes('codebookbytypewithid') ||
      request.url.includes('customer/keyinfo') ||
      request.url.includes('/customer/email/check')
    ) {
      request = request.clone({ headers: new HttpHeaders() });
      let transactionId =
        this.authService.getDateTimeNow() +
        '-' +
        this.authService.generateRandomNo();
      request = request.clone({
        headers: request.headers.set('Transaction-Id', transactionId),
      });
    }

    if (request.url.includes('/card/totalBalance?') || request.url.includes('firebase/token') || request.url.includes('/tokenGroup') || request.url.includes('/status?cardStatus') || request.url.includes('/topup')) {
      request = request.clone({
        headers: new HttpHeaders(),
      });
      let transactionId =
        this.authService.getDateTimeNow() +
        '-' +
        this.authService.generateRandomNo();
      request = request.clone({
        headers: request.headers.set('Transaction-Id', transactionId),
      });
      request = request.clone({
        headers: request.headers.set('Random-Number', t_token_1),
      });
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + t_token_2),
      });
    }
    if (
      request.url.includes(
        'customer/request/otp' ||
        'customer/send/otp' ||
        'customer/verify/otp' ||
        '/card/register' ||
        '/getNftCount' ||
        '/updateNftCount' ||
        '/customer/wallet' ||
        'googleauth/generate' ||
        'noti/push' ||
        'calculateFee'
      )
    ) {
      request = request.clone({
        headers: new HttpHeaders(),
      });
      let transactionId =
        this.authService.getDateTimeNow() +
        '-' +
        this.authService.generateRandomNo();
      request = request.clone({
        headers: request.headers.set('Transaction-Id', transactionId),
      });
      request = request.clone({
        headers: request.headers.set('Random-Number', t_token_1),
      });
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + t_token_2),
      });
    }

    if (
      request.url.includes('login/otp') ||
      request.url.includes('v2/request/otp') ||
      request.url.includes('login?')
    ) {
      request = request.clone({ headers: new HttpHeaders() });
      let transactionId =
        this.authService.getDateTimeNow() +
        '-' +
        this.authService.generateRandomNo();
      request = request.clone({
        headers: request.headers.set('Transaction-Id', transactionId),
      });
    }

    if (request.url.includes('login/verify/otp')) {
      request = request.clone({ headers: new HttpHeaders() });
      let transactionId =
        this.authService.getDateTimeNow() +
        '-' +
        this.authService.generateRandomNo();
      request = request.clone({
        headers: request.headers.set('Transaction-Id', transactionId),
      });
      request = request.clone({
        headers: request.headers.set('Random-Number', t_token_1),
      });
    }
    console.log('http request header >>> ', request);
    return request;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const timeoutValue = 60000;

    const isTranslationRequest = request.url.includes('/assets/i18n/');

    if (this.ongoingRequests === 0 && !isTranslationRequest) {
      if (request.url.includes('customer/register/verify/status') || request.url.includes('marketPrice?tokenGroups=')) {
        // this.helper.hideLoading();
      } else {
        this.helper.showLoading();
      }
    }
    this.ongoingRequests++;
    const tokenObservables = [
      from(this.secureStorageService.getValue('temp_token')).pipe(
        retryWhen((errors) =>
          errors.pipe(
            delay(1000),
            take(3),
            mergeMap((error) => {
              console.error('Retrying to fetch temp_token after error:', error);
              return throwError(error);
            })
          )
        ),
        catchError((error) => {
          console.error('Error fetching temp_token after retries:', error);
          return of(null); // Return a default value or handle the error appropriately
        })
      ),
      from(this.secureStorageService.getValue('r_number')).pipe(
        retryWhen((errors) =>
          errors.pipe(
            delay(1000),
            take(3),
            mergeMap((error) => {
              console.error('Retrying to fetch r_number after error:', error);
              return throwError(error);
            })
          )
        ),
        catchError((error) => {
          console.error('Error fetching r_number after retries:', error);
          return of(null); // Return a default value or handle the error appropriately
        })
      ),
      from(this.secureStorageService.getValue('session_token')).pipe(
        retryWhen((errors) =>
          errors.pipe(
            delay(1000),
            take(3),
            mergeMap((error) => {
              console.error(
                'Retrying to fetch session_token after error:',
                error
              );
              return throwError(error);
            })
          )
        ),
        catchError((error) => {
          console.error('Error fetching session_token after retries:', error);
          return of(null); // Return a default value or handle the error appropriately
        })
      ),
    ];

    return forkJoin(tokenObservables).pipe(
      mergeMap((tokenValues) => {
        // Process each token value
        const tokens: Tokens = tokenValues.reduce(
          (acc: Tokens, tokenValue, index) => {
            acc[`t_token_${index}`] = tokenValue; // Adjust this based on your token structure
            return acc;
          },
          {}
        );

        request = this.addAuthHeader(request, tokens);

        return next.handle(request).pipe(
          timeout(timeoutValue),
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              if (request.url.includes('customer/cprkkey')) {
              }
              if (event.body) {
                return event.clone({
                  body: event.body.body,
                });
              }
            }

            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            console.error('error.message error.message error.message :', error);
            this.handleResponseError(error, request);

            throw new Error(error.error.message);

            // return throwError(error.message);
          }),
          finalize(() => {
            this.ongoingRequests--;
            if (this.ongoingRequests === 0) {
              if (request.url.includes('customer/register/verify/status') || request.url.includes('marketPrice?tokenGroups=')) {
              } else {
                this.helper.hideLoading();
              }
            }
          })
        );
      }),
      catchError((error) => {
        console.error('Error in forkJoin:', error);
        if (error == 'Insufficient Balance') {
          this.helper.presentToast('Insufficient Balance!')
          this.navCtrl.navigateRoot('/tabs/crypto')
        }
        if (request.url.includes('customer/register/verify/status') || request.url.includes('marketPrice?tokenGroups=')) {

        } else {
          this.helper.hideLoading();
        }
        return throwError(error); // Optionally rethrow the error
      })
    );
  }

  async handleResponseError(
    error: any,
    request?: any,
    next?: any
  ): Promise<void | Observable<any>> {
    const r_no = (await this.secureStorageService.getValue('r_no')) ?? '';
    const device_info =
      (await this.secureStorageService.getValue('device_info')) ?? '';
    const refresn_token =
      (await this.secureStorageService.getValue('refresn_token')) ?? '';
    const customer_id =
      (await this.secureStorageService.getValue('customer_id')) ?? '';
    const transactionId =
      this.authService.getDateTimeNow() +
      '-' +
      this.authService.generateRandomNo();

    if (error.error) {
      let msg = error.message;
      let err = error.error;
      if (error.error.code) {
        if (err.code == 400 || err.code == 401 || err.code == 403) {
          const path_arr = ['/login'];
          if (path_arr.indexOf(this.location.path()) == -1) {
            if (
              err.message.includes('Token Expired') ||
              err.message.includes('Invalid Token')
            ) {
              this.helper.presentToast('Invalid Token');
            }
            if (err.code == 401 || err.code == 403) {
              return this.refreshToken(
                device_info,
                refresn_token,
                customer_id
              ).pipe(
                switchMap(() => {
                  request = this.addAuthHeader(request);
                  return next.handle(request);
                }),
                catchError((e) => {
                  if (e.status !== 401) {
                    return this.handleResponseError(
                      e
                    ) as Promise<void | Observable<any>>;
                  } else {
                    return EMPTY;
                  }
                })
              );
            }
          }
        } else if (err.code == 500) {
        }
      } else {
        if (error.status == 400 || error.status == 401) {
        } else if (error.status == 500) {
        } else if (error.status == 429) {
          this.helper.presentToast(
            'Too Many Requests, please wait for a minute.'
          );
        }
      }
    }
    return;
  }

  async cleanCache() {
    const keysToRemove = [
      'r_number',
      'session_token',
      'refresn_token',
      'decryptedPublicKey',
      'firebaseToken',
    ];
    await this.secureStorageService.removeValues(keysToRemove);
    await this.firebaseService.getFirebaseToken();
    await this.firebaseService.clearNotificationsOnLogout();
  }
}
