import { Component } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-numeric-input-alert',
  templateUrl: './numeric-input-alert.component.html',
  styleUrls: ['./numeric-input-alert.component.scss'],
})
export class NumericInputAlertComponent {

  constructor(private alertController: AlertController) { }

  async presentNumericInputAlert() {
    const alert = await this.alertController.create({
      header: 'Enter 6-digit Number',
      inputs: [
        { name: 'digit1', type: 'tel', max: 1, cssClass: 'numeric-input' },
        { name: 'digit2', type: 'tel', max: 1, cssClass: 'numeric-input' },
        { name: 'digit3', type: 'tel', max: 1, cssClass: 'numeric-input' },
        { name: 'digit4', type: 'tel', max: 1, cssClass: 'numeric-input' },
        { name: 'digit5', type: 'tel', max: 1, cssClass: 'numeric-input' },
        { name: 'digit6', type: 'tel', max: 1, cssClass: 'numeric-input' }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Ok',
          handler: (data) => {
            const numberEntered = Object.values(data).join('');
          },
        },
      ],
      cssClass: 'numeric-input-alert'
    });

    await alert.present();
  }
}
