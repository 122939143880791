import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router) { }

  // Extended generic routing function
  navigateToRoute(paths: string[], params?: any) {
    this.router.navigate(paths, { queryParams: params });
  }

  navigateToNotifications(route: string[], data?: any) {
    const navigationExtras: NavigationExtras = {
      state: { data }
    };
    this.router.navigate(route, navigationExtras);
  }
}
