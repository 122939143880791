import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Subscription, filter } from 'rxjs';
import { LoadingService } from 'src/app/core/services/helper/loading.service';
import { SecureStorageService } from 'src/app/core/services/storage/secure-storage.service';
import { AddFriendService } from 'src/app/features/add-friend/add-friend.service';
import { Friend } from 'src/app/features/add-friend/friend.dto';

@Component({
  selector: 'app-friend-list',
  templateUrl: './friend-list.component.html',
  styleUrls: ['./friend-list.component.scss'],
})
export class FriendListComponent implements OnInit {

  private subscription: Subscription = new Subscription();
  private routerSubscription!: Subscription;

  friends!: Friend[]
  walletId: any;

  // friends: Friend[] = [
  //   {id: '1', name: 'James', email: 'james@gmail.com' },
  //   {id: '2', name: 'Harvel', email: 'harvel@gmail.com' },
  //   {id: '3', name: 'Marvel', email: 'marvel@gmail.com' },
  //   {id: '4', name: 'Iron Man', email: 'ironman@gmail.com' },
  //   {id: '5', name: 'Thor', email: 'thor@gmail.com' },
  //   {id: '6', name: 'Spider Man', email: 'spiderman@gmail.com' },
  //   {id: '7', name: 'Tharnos', email: 'tharnos@gmail.com' },

  // ]


  constructor(private location: Location, private navCtrl: NavController, private addFriendService: AddFriendService, private storageService: SecureStorageService, private helper: LoadingService, private router: Router, private modalControl: ModalController) { }

  async ngOnInit() {
    this.walletId = await this.storageService.getValue('wallet_id');
    this.getAllFriends()

    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter((event: any) => event.urlAfterRedirects === '/friend-lists')
    ).subscribe(() => {
      this.getAllFriends()
    });

    this.subscription.add(this.routerSubscription);
  }

  getFriendId(data: any) {
    this.modalControl.dismiss({
      dismissed: true,
      data: data
    });
  }

  addFriend() {
    this.navCtrl.navigateForward('/add-friend');
  }

  handleRefresh(event: any) {
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

  getAllFriends() {
    this.addFriendService.getFriendList(this.walletId).subscribe((res: any) => {
      this.friends = res.data.content
    })
  }

  dismiss() {
    this.modalControl.dismiss();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

}
