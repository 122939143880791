<!-- 
  <ion-button id="open-modal" expand="block">Open</ion-button> -->
<ion-content class="ion-padding" [fullscreen]="true">
  <app-toast-message></app-toast-message>
  <div class="page-container">
    <div class="top-container">
      <div class="top-bar">
        <img
          src="../../../../assets/icon/Icons/SVG/type=close.svg"
          (click)="cancel()"
          class="back-button"
          alt=""
        />
      </div>
      <div class="top-bar-content">
        <h3>OTP Confirmation</h3>
      </div>
    </div>
  </div>

  <div class="otp-container">
    <img
      src="/assets/images/mail_open.png"
      alt=""
    />
    <p>Please get OTP code through your email and enter the OTP code.</p>

    <form [formGroup]="otpForm">
      <ion-item lines="none">
        <div class="otp-inputs">
          <ion-input
            type="tel"
            maxlength="1"
            formControlName="digit1"
            aria-label="aid:digit1_input" contentDescription="aid:digit1_input"
          ></ion-input>
          <ion-input
            type="tel"
            maxlength="1"
            formControlName="digit2"
            aria-label="aid:digit2_input" contentDescription="aid:digit2_input"
          ></ion-input>
          <ion-input
            type="tel"
            maxlength="1"
            formControlName="digit3"
            aria-label="aid:digit3_input" contentDescription="aid:digit3_input"
          ></ion-input>
          <ion-input
            type="tel"
            maxlength="1"
            formControlName="digit4"
            aria-label="aid:digit4_input" contentDescription="aid:digit4_input"
          ></ion-input>
          <ion-input
            type="tel"
            maxlength="1"
            formControlName="digit5"
            aria-label="aid:digit5-input" contentDescription="aid:digit5-input"
          ></ion-input>
          <ion-input
            type="tel"
            maxlength="1"
            formControlName="digit6"
            aria-label="aid:digit6_input" contentDescription="aid:digit6_input"
          ></ion-input>
        </div>
      </ion-item>
    </form>
    <ion-list class="otp-list">
      <p>Paste OTP</p>
      <div class="otp-mail">
        <ion-button class="submit-Otp-button" aria-label="aid:get_otp_btn" contentDescription="aid:get_otp_btn" (click)="getOtp()">
          <!-- <ion-icon slot="start" name="mail-outline"></ion-icon> -->
          <ion-icon name="paper-plane-outline"></ion-icon>
          <!-- <img src="../../../../assets/icon/Icons/SVG/type=send.svg" alt=""> -->

          Get OTP via Email
        </ion-button>
      </div>
    </ion-list>
  </div>
</ion-content>
