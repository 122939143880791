import { Injectable } from '@angular/core';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {

  async getValue(key: string): Promise<string | null> {
    try {
      const result = await SecureStoragePlugin.get({ key });
      return result.value;
    } catch (error: any) {
      if (error.includes('Item with given key does not exist')) {
        return null;
      } else {
        throw error;
      }
    }
  }

  async setValue(key: string, value: string): Promise<void> {
    await SecureStoragePlugin.set({ key, value });
  }

  async getOrGenerateValue(key: string, generateFn: () => string): Promise<string> {
    const value = await this.getValue(key);
    if (value === null) {
      const newValue = generateFn();
      await this.setValue(key, newValue);
      return newValue;
    }
    return value;
  }

  async removeValue(key: string): Promise<void> {
    try {
      await SecureStoragePlugin.remove({ key });
    } catch (error: any) {
      if (!error.message.includes('Item with given key does not exist')) {
        throw error;
      }
    }
  }

  async valueExists(key: string): Promise<boolean> {
    const value = await this.getValue(key);
    return value !== null;
  }

  async removeValues(keys: string[]): Promise<void> {
    for (const key of keys) {
      if (await this.valueExists(key)) {
        await this.removeValue(key);
      }
    }
  }

  // Method to clear all secure storage data
  async clear(): Promise<void> {
    try {
      await SecureStoragePlugin.clear();
      console.log('All secure storage data cleared');
    } catch (error) {
      console.error('Error clearing secure storage', error);
    }
  }
}
