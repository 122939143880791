import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignInPageModule } from './features/auth/sign-in/sign-in.module';
import { SharedModule } from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient } from '@angular/common/http';
import { HttpConfigInterceptor } from './core/services/auth/auth.interceptor';
import { MaskingPipe } from './shared/pipes/masking.pipe';
import { CommonModule } from '@angular/common';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { addIcons } from 'ionicons';
import { AppInitializerService, initializeAppFactory } from './core/services/app-initializer/app-initializer.service';
import { environment } from 'src/environments/environment.prod';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [AppComponent],
  imports: [BrowserAnimationsModule, BrowserModule, IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }), AppRoutingModule, ReactiveFormsModule, SharedModule,
    NgbModule,
    NgbModalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    HttpClientModule, AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),

  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy, }, { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeAppFactory,
    deps: [AppInitializerService],
    multi: true,
  }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private sanitizer: DomSanitizer) {
    this.registerCustomIcons();
  }

  registerCustomIcons() {
    const icons = {
      'type-send': 'assets/icons/type-send.svg',
      'c-card': 'assets/bottom-nav-icons/c_card.svg',
      'c-crypto': 'assets/bottom-nav-icons/c_crypto.svg',
      'c-financial': 'assets/bottom-nav-icons/c_financial.svg',
      'c-home': 'assets/bottom-nav-icons/c_home.svg',
      'c-more': 'assets/bottom-nav-icons/c_more.svg',
      'c-card-fill': 'assets/bottom-nav-icons/c_card_fill.svg',
      'c-crypto-fill': 'assets/bottom-nav-icons/c_crypto_fill.svg',
      'c-financial-fill': 'assets/bottom-nav-icons/c_financial_fill.svg',
      'c-home-fill': 'assets/bottom-nav-icons/c_home_fill.svg',
      'c-more-fill': 'assets/bottom-nav-icons/c_more_fill.svg',
      'nav-back-btn': 'assets/icon/backArror.svg'
    };

    addIcons(icons);
  }
}
