<div class="modal-content">
    <div class="modal-body" *ngIf="type=='create_wallet'">
        You can fill in the email address that used when you registered as a MediEarth Member, or if you prefer, you can use different email address.    </div>
        <div class="modal-body" *ngIf="type=='sign_in'">
            To proceed, please enter the security key you set during the sign-up process. This key is required to ensure the safety of your wallet and to confirm that only you can authorize transactions. Without the correct key, access to your wallet or other transaction will be restricted for security reasons.    </div>
    <div class="modal-footer">
        <div aria-label="aid:close-btn" contentDescription="aid:close-btn" (click)="closeModal()">
            <ion-icon name="close"></ion-icon>
        </div>
    </div>
  </div>
  