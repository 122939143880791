<div class="modal-header">
  <h4 class="modal-title">Select Range</h4>
  <ion-icon name="close" class="close" (click)="modal.dismiss('Cross click')"></ion-icon>
</div>
<div class="modal-body">
  <mat-calendar (selectedChange)="_onSelectedChange($event)" [selected]="selectedDateRange" aria-label="aid:mat-calendar" contentDescription="aid:mat-calendar">
  </mat-calendar>
  <p *ngIf="toast"  style="display: block;
  margin: 0;
  text-align: right;
  color: red;">Please select more than one date!</p>
</div>
<div class="modal-footer" style="gap: 20px;">
  <button mat-button (click)="cancel()" style="border: none;
  background: none; color: black;" aria-label="aid:date-cancel" contentDescription="aid:date-cancel">Cancel</button>
  <button mat-button (click)="apply()" style="border: none;
  background: none; color: black;" aria-label="aid:date-apply" contentDescription="aid:date-apply">Apply</button>
</div>