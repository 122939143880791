import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private firebaseInfo: BehaviorSubject<boolean>;

  private _loading = new Subject<boolean>();
  loading$ = this._loading.asObservable();

  constructor(
    private toastCtrl: ToastController
  ) {
    this.firebaseInfo = new BehaviorSubject<boolean>(false);
  }

  showToast(msg = 'Toast', duration = 3000, color = 'dark') {
    this.toastCtrl
      .create({
        message: msg,
        duration: duration,
        color: color,
      })
      .then((obj: any) => {
        obj.present();
      });
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      position: 'top',
      cssClass: 'toast-custom',
    });
    toast.present();
  }

  getObservableValue(): Observable<boolean> {
    return this.firebaseInfo.asObservable();
  }
  setObservableValue(newValue: boolean): void {
    this.firebaseInfo.next(newValue);
  }

  showLoading() {
    this._loading.next(true);
  }

  hideLoading() {
    this._loading.next(false);
  }
}
