export const environment = {
  production: false,
  apiUrl: 'https://dev.mediearth.wallet.blue-stone.net/mediearth/api/v2/',
  firebase: {
    apiKey: 'AIzaSyBcxlHaonXxO730byKGByX3WcYh_eD9Kxs',
    authDomain: 'mediearth-crm-ms-dev.firebaseapp.com',
    projectId: 'mediearth-crm-ms-dev',
    storageBucket: 'mediearth-crm-ms-dev.appspot.com',
    messagingSenderId: '483649693015',
    appId: '1:483649693015:web:49dd6fa03a4c1bb9008a12',
    measurementId: 'G-D50VBHF71Z',
    vapidKey: 'BAHJvI1cMTMsJUDVDr1TZuKTp0YFrwVY0ytwk3iTKcafuRTS5XEFDPBl0A-pR49Aqw7o1SON2vjc8g-Y_XO2hio'
  }
};