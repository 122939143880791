import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VisaCardComponent } from './components/visa-card/visa-card.component';
import { NumericInputAlertComponent } from './components/numeric-input-alert/numeric-input-alert.component';
import { GenericFormComponent } from './components/generic-form/generic-form.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { SecurityKeysComponent } from './components/security-keys/security-keys.component';
import { OtpAlertComponent } from './components/otp-alert/otp-alert.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { MaskingPipe } from './pipes/masking.pipe';
import { EmailToolTipsComponent } from './components/email-tool-tips/email-tool-tips.component';
import { SecurityKeySuccessComponent } from './components/security-key-success/security-key-success.component';
import {
  MatNativeDateModule,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { DefaultMatCalendarRangeStrategy, MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { DateComponent } from './components/date/date.component';
import { NgxCurrencyDirective } from "ngx-currency";
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { FriendListComponent } from './components/friend-list/friend-list.component';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { CustomLoadingComponent } from './components/custom-loading/custom-loading.component';
import { LoanSearchComponent } from './components/loan-search/loan-search.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
  declarations: [GenericFormComponent, VisaCardComponent, NumericInputAlertComponent, ToastMessageComponent, SecurityKeysComponent, OtpAlertComponent, AlertMessageComponent, MaskingPipe, EmailToolTipsComponent, SecurityKeySuccessComponent, DateComponent, FriendListComponent, CustomLoadingComponent, LoanSearchComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxCurrencyDirective,
    TranslateModule,
    NgSelectModule,
    QRCodeModule,
    NgxScannerQrcodeModule,
    LottieComponent,
    NgxMaskDirective, NgxMaskPipe
  ],
  exports: [GenericFormComponent, VisaCardComponent, NumericInputAlertComponent, ToastMessageComponent, SecurityKeysComponent, OtpAlertComponent, AlertMessageComponent, MaskingPipe, NgxCurrencyDirective, TranslateModule, NgSelectModule, FormsModule, ReactiveFormsModule, QRCodeModule, FriendListComponent, NgxScannerQrcodeModule, CustomLoadingComponent, NgxCurrencyDirective, CurrencyPipe, NgxMaskDirective, NgxMaskPipe, LoanSearchComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [provideNativeDateAdapter(),
    CurrencyPipe, provideNgxMask(),
  {
    provide: MAT_SELECT_CONFIG,
    useValue: { overlayPanelClass: 'customClass' },
    useClass: DefaultMatCalendarRangeStrategy,
  },
  provideLottieOptions({
    player: () => import('lottie-web'),
  }),
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,

})
export class SharedModule { }
