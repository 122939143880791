import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { register } from "swiper/element/bundle";
import { FirebaseServiceService } from './core/services/firebase-service/firebase-service.service';
import { LoadingService } from './core/services/helper/loading.service';
import { AppInitializerService } from './core/services/app-initializer/app-initializer.service';
import { NetworkService } from './core/services/auth/network.service';
import { first, Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SecureStorageService } from './core/services/storage/secure-storage.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  status = [];
  isOnline: boolean = true;
  private subscriptions: Subscription = new Subscription();

  loading$: Observable<boolean>;

  constructor(private swUpdate: SwUpdate, private sss: SecureStorageService, private helper: LoadingService, private networkService: NetworkService, public translate: TranslateService) {
    this.checkForUpdates();
    this.loading$ = this.helper.loading$;
    translate.addLangs(['en', 'mm']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang?.match(/en|mm/) ? browserLang : 'en');
  }

  async ngOnInit() {
    this.subscriptions.add(
      this.networkService.isOnline$.subscribe(status => {
        this.isOnline = status;
        if (this.isOnline == false) {
          this.helper.presentToast('Your internet connection is currently offline!');
        } else {
          this.helper.presentToast('Your are back to online.');
        }
      })
    );
    const rn = await this.sss.getValue('r_number');
  }

  checkForUpdates() {
    if (this.swUpdate.isEnabled) {

      // Check for updates immediately on app launch
      this.swUpdate.checkForUpdate().catch((err) => {
        console.error('Error when checking for update', err);
      });

      setInterval(() => {
        this.swUpdate.checkForUpdate();
      }, 4 * 60 * 60 * 1000);

      this.swUpdate.versionUpdates.pipe(first()).subscribe(versionEvent => {
        if (versionEvent.type === 'VERSION_READY') {
          /**
           * // Show a custom dialog instead of confirm
           this.helper.presentToast('A new version is available! Please reload the page to update.', true); 
           // Optionally, reload automatically after a delay
           setTimeout(() => window.location.reload(), 10000); // 10 seconds delay before auto-reload
           */
          if (confirm('New version available. Load New Version?')) {
            window.location.reload();
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
