<ion-page>
  <ion-content>
    <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <div class="background-image">
      <div class="container">
        <div class="row justify-content-center">
          <!-- test localization -->
          <!-- <div class="header-icons">
        <img src="../../../assets/icon/Icons/SVG/type=hide.svg" *ngIf="!shareService.eyeActive"
        (click)="shareService.eyeActive = true" alt="">
        <div class="dropdown">
          <img type="button" class="dropdown-toggle" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false" src="../../../assets/icon/Icons/SVG/type=translator.svg" alt="">
          <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
            <li *ngFor="let lang of translate.getLangs()">
                <button (click)="selectLanguage(lang)" class="dropdown-item" type="button">{{ lang }}</button>
            </li>
          </ul>
        </div>
      </div> -->
          <!-- end localization -->
          <div class="col-lg-6 col-md-8 col-sm-12 top-third">
            <button class="back-arrow" *ngIf="formName !== 'SI' && formName !== 'REG'" (click)="goBack()"
              aria-label="aid:btn-back" contentDescription="aid:btn-back">
              <ion-icon name="arrow-back"></ion-icon>
            </button>
            <img *ngIf="imageUrl" [src]="imageUrl" [ngStyle]="getImageStyle()" alt="dynamic image" />
          </div>
        </div>
      </div>

      <div class="container" style="padding: 0px 20px;">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-8 col-sm-12" *ngIf="formName === 'CNW'">
            <h1 style="font-weight: 800;">Create new wallet account</h1>
            <p>Please fill your infformation to create a new account for MediCubo Wallet.</p>
          </div>
          <div class="col-lg-6 col-md-8 col-sm-12" *ngIf="formName === 'CSP' && previousRoute === 'VEO'">
            <h1 style="font-weight: 800;">{{"FORGET_PASSWORD.resetPwd" | translate}}</h1>
            <p>Please reset your password with your new secure password.</p>
          </div>
          <div class="col-lg-6 col-md-8 col-sm-12" *ngIf="formName === 'VEO'">
            <h1 style="font-weight: 800;">Verify your email</h1>
            <p>We send an OTP code to your MediCubo member mai to verify your account. Check your email and fill the
              OTP
              code here.</p>
          </div>
          <div class="col-lg-6 col-md-8 col-sm-12" *ngIf="formName === 'VE'">
            <h1 style="font-weight: 800;">Verify your email</h1>
            <p>We send an email to verify your account. Check your email and click “Verify” button in the email within
              10
              minutes.</p>
          </div>
          <!-- <div class="col-lg-6 col-md-8 col-sm-12" *ngIf="previousRoute === 'CNW'">
            <h1 style="font-weight: 800;">Create Wallet Login Password</h1>
            <p>Please set a strong password so we can provide you the highest level of security for your assets.</p>
          </div> -->
          <div class="col-lg-6 col-md-8 col-sm-12" *ngIf="formName === 'CSP' && previousRoute !== 'VEO'">
            <h1 style="font-weight: 800;">Create Wallet Login Password</h1>
            <p>Please set a strong password so we can provide you the highest level of security for your assets.</p>
          </div>
          <div class="col-lg-6 col-md-8 col-sm-12" *ngIf="formName === 'CP'">
            <h1 style="font-weight: 800;">Confirm password</h1>
            <p>All right, please enter your password again.</p>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8 col-sm-12">
              <ion-note class="col-lg-6 col-md-8 col-sm-12" *ngIf="formName === 'SI'"
                class="page_title">{{"SIGN_UP.signIn" | translate}}</ion-note>

              <ion-note class="col-lg-6 col-md-8 col-sm-12" *ngIf="formName === 'REG'" class="page_title">Register new
                account</ion-note>
              <div class="row justify-content-center">
                <div *ngIf="formName === 'REG'">
                  <p>Please enter your MediCubo Member Code so that we can verify your membership.</p>
                </div>
                <div *ngIf="formName === 'SI'">
                  <p style="color: #1c1c1c; margin: 10px 0px;">{{"SIGN_UP.message" | translate}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-8 col-sm-12">
            <form [formGroup]="form" (ngSubmit)="onSubmit()" [ngClass]="formName == 'VEO' ? 'otp-form' : 'auth-form'">
              <div *ngFor="let field of fields" class="fields-padding">
                <div *ngIf="field.type === 'checkbox' && formName === 'VE'">
                  <ion-item lines="none" style="display: flex;">
                    <ion-label aria-label="aid:email-otp" contentDescription="aid:email-otp">Didn’t receive your email?
                      &nbsp; <ion-note (click)="resendEmailVerificationLink()">Resend</ion-note></ion-label>
                  </ion-item>
                </div>
                <div style="padding-top: 10px;"
                  *ngIf="formName !== 'VEO' && formName !== 'REG' && formName !=='SI' && formName !== 'VE' && formName !== 'CSP' && formName !== 'CP'">
                  <ion-note class="label-note">{{ field.label
                    }}</ion-note>
                </div>

                <ion-item *ngIf="field.type !== 'checkbox' && field.type !== 'tel'" class="item-padding" lines="none">
                  <ion-input *ngIf="field.type === 'text'" formControlName="{{ field.name }}" type="text"
                    placeholder="Member Code" class="my-input" id="member-code-input" aria-label="aid:member-code-input"
                    contentDescription="aid:member-code-input"></ion-input>
                  <ion-input *ngIf="field.type === 'email' && formName !== 'VE'" formControlName="{{ field.name }}"
                    class="my-input" type="email" [ngClass]="'custom-input'" placeholder="{{ field.label }}"
                    aria-label="aid:email-input" contentDescription="aid:email-input"></ion-input>
                  <ion-input *ngIf="field.type === 'email' && formName === 'VE'" formControlName="{{ field.name }}"
                    type="email" placeholder="{{ field.placeholder }}" class="my-input1" readonly [disabled]="true"
                    aria-label="aid:email-input" contentDescription="aid:email-input"></ion-input>

                  <ion-input *ngIf="field.type === 'password'" formControlName="{{ field.name }}" [type]="ptype"
                    [ngClass]="'custom-input'" (ionInput)="onPasswordInput()" class="my-input"
                    placeholder="{{ field.placeholdername }}" aria-label="aid-password"
                    contentDescription="aid-password">
                    <ion-icon slot="end" name="eye-off" *ngIf="!eyeActive && field.type === 'password'"
                      (click)="switchType()"></ion-icon>
                    <ion-icon slot="end" name="eye" *ngIf="eyeActive && field.type === 'password'"
                      (click)="switchType()"></ion-icon>
                  </ion-input>
                  <!-- <div *ngIf="field.type === 'password'" class="header-icons">
                    <img *ngIf="eyeActive" (click)="switchType()" src="../../../assets/icon/Icons/SVG/type=view.svg"
                      alt="Show password">
                    <img *ngIf="!eyeActive" (click)="switchType()" src="../../../assets/icon/Icons/SVG/type=hide.svg"
                      alt="Hide password">
                  </div> -->

                  <ion-input *ngIf="field.type === 'otp'" formControlName="{{ field.name }}" type="text"
                    placeholder="{{ field.label }}" class="my-input" aria-label="aid:otp-input"
                    contentDescription="aid:otp-input"></ion-input>
                  <ion-input *ngIf="field.type === 'number'" formControlName="{{ field.name }}" type="number"
                    placeholder="{{ field.label }}" class="my-input" aria-label="aid:number-input"
                    contentDescription="aid:number-input"></ion-input>
                </ion-item>
                <ion-item *ngIf="field.type === 'checkbox'" lines="none">
                  <ion-label *ngIf="formName !== 'VE'" aria-label="aid:not-ve-checkbox-label"
                    contentDescription="aid:not-ve-checkbox-label">{{ field.label }}</ion-label>
                  <label style="padding-top: 16px;" *ngIf="formName === 'VE'" [innerHtml]="field.label"
                    aria-label="aid:ve-checkbox-label" contentDescription="aid:ve-checkbox-label"></label>
                  <ion-checkbox slot="start" formControlName="{{ field.name }}" aria-label="aid:checkbox-start"
                    id="privacy-checkbox" placeholder="privacy-checkbox"
                    contentDescription="aid:checkbox-start"></ion-checkbox>
                </ion-item>
                <div *ngIf="field.type === 'radio'">
                  <ion-radio-group value="custom-checked" aria-label="aid:custom-checkox-gp"
                    contentDescription="aid:custom-checkox-gp">
                    <ion-radio value="custom" aria-label="aid:custom-checkbox"
                      contentDescription="aid:custom-checkbox">{{ field.label }}</ion-radio>
                    <ion-radio value="custom-checked" aria-label="aid:custom-checkbox-checked"
                      contentDescription="aid:custom-checkbox-checked">{{ field.label
                      }}</ion-radio>
                  </ion-radio-group>
                </div>
                <div *ngIf="field.type === 'tel'" class="otp-inputs">
                  <ion-input class="otp-input-field" type="tel" maxlength="1" formControlName="{{ field.name }}"
                    aria-label="aid:tel-input" contentDescription="aid:tel-input"></ion-input>
                </div>
                <div *ngIf="formName === 'CNW'">
                  <label for="phone">Phone Number</label>
                  <ion-radio-group (ionChange)="onCountryChange($event)" aria-label="aid:phno-radio-gp"
                    contentDescription="aid:phno-radio-gp">
                    <ion-radio value="korea" labelPlacement="end" aria-label="aid:korea-radio"
                      contentDescription="aid:korea-radio" style="margin-right: 20px">Korea</ion-radio>
                    <ion-radio value="thai" labelPlacement="end" aria-label="aid:thai-radio"
                      contentDescription="aid:thai-radio">Thai</ion-radio>
                    <ion-radio value="other" labelPlacement="end" aria-label="aid:other-radio"
                      contentDescription="aid:other-radio" style="margin-left: 20px">Other</ion-radio>
                  </ion-radio-group>
                  <ion-row>
                    <ion-col size="3"><ion-input [(ngModel)]="initialNumber" aria-label="aid:initial-no-input"
                        contentDescription="aid:initial-no-input" /></ion-col>
                    <ion-col size="9"><ion-input id="phone" type="tel" aria-label="aid:tel-input1"
                        contentDescription="aid:tel-input1" /></ion-col>
                  </ion-row>
                </div>
                <ion-text style="margin-left: 20px;"
                  *ngIf="field.validationMessage && !form.controls[field.name].valid && form.controls[field.name].touched"
                  color="danger" aria-label="aid:validation-message" contentDescription="aid:validation-message">
                  {{ field.validationMessage }}
                </ion-text>
                <div *ngIf="field.name === 'secure-password'" class="password-validation">
                  <div *ngFor="let validation of passwordValidations" class="validation-item">
                    <ion-icon [name]="validation.valid ? 'checkmark-circle' : 'ellipse-outline'"
                      [color]="validation.valid ? 'success' : 'medium'"></ion-icon>
                    <ion-label class="password-validation-label" aria-label="aid:validation-message1"
                      contentDescription="aid:validation-message1">{{ validation.message }}</ion-label>
                  </div>
                </div>
              </div>

              <div *ngIf="formName === 'SI'" class="col-lg-6 col-md-8 col-sm-12"
                style="text-align: right; width: auto; padding-top: 15px;">
                <div>
                  <ion-note class="center-note" (click)="nav()">{{forgotPass}}</ion-note>
                </div>
              </div>


              <div *ngIf="formName !== 'VEO' && formName !== 'CP' && formName !== 'CSP'" class="button-div row">
                <div *ngFor="let button of buttons" style="text-align: center; padding-top: 1rem;">
                  <ion-note *ngIf="button.type !== 'submit'" (click)="button.action && button.action()"
                    class="non-submit-button" [ngClass]="'custom-note'" aria-label="aid:clear-btn"
                    contentDescription="aid:clear-btn">{{ button.label
                    }}</ion-note>
                  <ion-button *ngIf="button.type === 'submit' && formName !== 'VE'" class="sign-in-btn" type="submit"
                    [disabled]="form.invalid" aria-label="aid:submit-btn" contentDescription="aid:submit-btn">{{
                    button.label }}</ion-button>
                  <ion-button *ngIf="button.type === 'submit' && formName === 'VE'" class="sign-in-btn" type="submit"
                    [disabled]="form.invalid || emailVerifyStatus == 0" aria-label="aid:submit-btn-1"
                    contentDescription="aid:submit-btn-1">{{
                    button.label }}</ion-button>
                </div>
              </div>

              <div *ngIf="formName === 'CP' || formName === 'CSP'" class="button-div row">
                <div *ngFor="let button of buttons" style="text-align: end;">
                  <ion-button *ngIf="button.type !== 'submit'" (click)="button.action && button.action()" fill="clear"
                    aria-label="aid:clear-btn1" contentDescription="aid:clear-btn1">{{ button.label }}</ion-button>
                  <ion-button *ngIf="button.type === 'submit'" class="sign-in-btn" type="submit"
                    [disabled]="form.invalid" aria-label="aid:submit-btn2" contentDescription="aid:submit-btn2">{{
                    button.label }}</ion-button>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="formName === 'VEO'" class="button-div row">
            <div *ngFor="let button of buttons">
              <ion-button *ngIf="button.type !== 'submit'" (click)="button.action && button.action()" fill="clear"
                aria-label="aid:clear-btn2" contentDescription="aid:clear-btn2">{{
                button.label }}</ion-button>
              <ion-button *ngIf="button.type === 'submit'" class="sign-in-btn" type="submit" [disabled]="form.invalid"
                aria-label="aid:submit-btn3" contentDescription="aid:submit-btn3">{{
                button.label }}</ion-button>
            </div>
          </div>
        </div>

      </div>

      <div class="container bottom-20-percent" *ngIf="formName === 'REG'">
        <!-- Content for the bottom 20% section -->
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-8 col-sm-12">
            <div>
              <ion-note class="reg-center-note" aria-label="aid:alreadyHaveAcc-note"
                contentDescription="aid:alreadyHaveAcc-note">{{alreadyHaveAcc}}</ion-note>
            </div>
          </div>

        </div>
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-8 col-sm-12">
            <div>
              <ion-note class="center-note" (click)="nav()" aria-label="aid:sign-in-btn"
                contentDescription="aid:sign-in-btn">{{signIn}}</ion-note>
            </div>
          </div>

        </div>
      </div>


      <!-- <div class="row justify-content-center content-right" *ngIf="formName === 'REG'">
        <div class="col-lg-6 col-md-8 col-sm-12">
          <div>
            
          </div>
        </div>
      </div> -->
    </div>
  </ion-content>
</ion-page>