<ion-content [fullscreen]="true">
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="background-image">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 top-third">
          <ion-list style="--background: transparent;" class="account-title">
            <ion-item style="--background: transparent;">
              <img class="close-btn" src="../../../../assets/icon/Icons/PNG/type=close.png" alt="" (click)="dismiss()">
              <ion-label aria-label="aid:your-friend" contentDescription="aid:your-friend">
                {{"FORM.your_friends" | translate}}</ion-label>
            </ion-item>
          </ion-list>
        </div>
      </div>
    </div>

    <div class="container" style="padding: 0px 20px;">
      <div class="row justify-content-center">
        <div class="col-12">
          <ion-list class="tickets" *ngIf="friends && friends.length > 0; else noFriends">
            <h1 class="fw-6 pt-16 friend-list-title">{{"FORM.friend_list" | translate}}</h1>
            <ion-card class="friend" *ngFor="let friend of friends">
              <ion-card-header (click)="getFriendId(friend)">
                <span>{{friend.friendEmail}}</span>
                <ion-card-title *ngIf="friend.nickName === null" >{{friend.friendName}}</ion-card-title>
                <ion-card-title *ngIf="friend.nickName !== null">{{friend.nickName}}</ion-card-title>
              </ion-card-header>

            </ion-card>
          </ion-list>
          <ng-template #noFriends>
            <ion-list>
              <ion-item lines="none">
                <ion-title style="font-weight: 600;" aria-label="aid:friend-list1" contentDescription="aid:friend-list1">{{"FORM.friend_list" | translate}}</ion-title>
              </ion-item>
              <ion-list class="noFriend">
                <div class="center-content">
                  <img src="../../../assets/icon/Icons/SVG/type=friends.svg" alt="">
                  <p style="font-weight: 400;">{{"FORM.no_friend_yet" | translate}}</p>
                </div>
              </ion-list>

            </ion-list>

          </ng-template>
        </div>
      </div>
    </div>
  </div>

</ion-content>
