import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormNames } from '../../utils/form-names.enum';
import { debounceTime } from 'rxjs';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoadingService } from 'src/app/core/services/helper/loading.service';
import { PkPukDecryptEncryptoService } from 'src/app/core/services/pk-puk-decrypt-encrypt/pk-puk-decrypt-encrypto.service';
import { SecureStorageService } from '../../../core/services/storage/secure-storage.service';
import { CustomAlertService } from '../../../core/services/custom-alert/custom-alert.service';
import { ShareService } from 'src/app/core/services/auth/share.service';
import { Masking_Type } from '../../dto/masking-type.dto';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { FirebaseServiceService } from 'src/app/core/services/firebase-service/firebase-service.service';

interface FieldConfig {
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  validation?: any[];
  validationMessage?: string;
  placeholdername?: string;
}

interface ButtonConfig {
  label: string;
  type: string; // 'button' or 'submit'
  action?: () => void;
}

interface PasswordValidation {
  message: string;
  valid: boolean;
}

@Component({
  selector: 'app-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss']
})
export class GenericFormComponent implements OnInit {
  @Input() title: string = '';
  @Input() emailVerifyStatus!: any;
  @Input() fields: FieldConfig[] = [];
  @Input() buttons: ButtonConfig[] = [];
  @Input() formName: FormNames | undefined;
  @Input() imageUrl: string = ''; // New input for the dynamic image URL
  @Input() previousRoute?: string; // New input for the dynamic image URL
  @Output() formDataChange = new EventEmitter<any>();
  @Output() formSubmit = new EventEmitter<any>();  // New Output for form submission

  alreadyHaveAcc = 'Already have an accout?';
  rememberPass = 'You don’t remember your Password?';
  forgotPass = 'Forgot Password';
  signIn = 'Sign In';
  ptype: any = 'password';
  eyeActive = false;
  form!: FormGroup;
  maskingType = Masking_Type;

  passwordValidations: PasswordValidation[] = [
    { message: 'Needs to be 8-50 characters long', valid: false },
    { message: 'At least 1 uppercase letter (A-Z)', valid: false },
    { message: 'At least 1 number (0-9)', valid: false },
    { message: 'At least one special character (!, #, $, %, etc.)', valid: false }
  ];
  initialNumber: string = '';

  constructor(private customAlertService: CustomAlertService, private secureStorageService: SecureStorageService,
    private keyService: PkPukDecryptEncryptoService, private helper: LoadingService, private authService: AuthService,
    private navCtrl: NavController, private router: Router, private fb: FormBuilder, public shareService: ShareService,
    public translate: TranslateService,
    private cdf: ChangeDetectorRef,
    private navService: NavigationService,
    private firebaseService: FirebaseServiceService
  ) { }

  async ngOnInit() {
    this.form = this.fb.group({});
    this.fields.forEach(field => {
      const control = this.fb.control('', field.validation || []);
      this.form.addControl(field.name, control);
    });

    this.form.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      this.formDataChange.emit(value);
    });

    if (this.formName === 'CNW') {
      const email = await this.secureStorageService.getValue('user_email');
      this.form.patchValue({ email });
    } else if (this.formName === 'VE') {
      const email = await this.secureStorageService.getValue('user_email');
      const privacyPolicy = false;
      const verifiedStatus = null;
      this.form.patchValue({ verifiedStatus });
      this.form.patchValue({ email });
      this.form.patchValue({ privacyPolicy });
    }
  }
  selectLanguage(lang: string) {
    this.translate.use(lang);
    // this.secureStorageService.setValue('lang', this.translate.use(lang))
  }

  goBack() {
    this.navCtrl.back();
  }

  nav() {
    if (this.formName === 'SI') {
      this.navService.navigateToRoute(['auth/forgot-password']);
    } else if (this.formName === 'REG') {
      this.navCtrl.navigateRoot(['auth/sign-in']);
    }
  }

  getImageStyle() {
    let maxWidth = '';
    if (this.formName === 'REG' || this.formName === 'SI') {
      maxWidth = '18rem';
    } else {
      maxWidth = '7rem';
    }
    return { 'max-width': maxWidth };
  }

  onSubmit() {
    if (this.form.valid) {
      this.formSubmit.emit(this.form.value);  // Emit form value on submit
    }
  }

  onPasswordInput() {
    if (this.formName === 'CSP') {
      const password = this.form.get('secure-password')?.value || '';
      this.passwordValidations[0].valid = password.length >= 8 && password.length <= 50;
      this.passwordValidations[1].valid = /[A-Z]/.test(password);
      this.passwordValidations[2].valid = /\d/.test(password);
      this.passwordValidations[3].valid = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    } else if (this.formName === 'CP') {
      const password = this.form.get('confirm_password')?.value || '';
      this.passwordValidations[0].valid = password.length >= 8 && password.length <= 50;
      this.passwordValidations[1].valid = /[A-Z]/.test(password);
      this.passwordValidations[2].valid = /\d/.test(password);
      this.passwordValidations[3].valid = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    }
  }

  resendOtp() {
    // Implement your resend OTP logic here
    console.log('Resend OTP clicked');
  }

  async resendEmailVerificationLink() {
    const f_token = await this.secureStorageService.getValue('firebaseToken');
    const member_id = await this.secureStorageService.getValue('member_id');
    const user_email = await this.secureStorageService.getValue('user_email');
    await this.keyService.generateKeyPairForEmailPasswordEncryptDecrypt(user_email!, 'email');
    const encryptedEmail = await this.secureStorageService.getValue('encryptedEmail');
    const postData = {
      email: encryptedEmail,
      firebaseToken: f_token,
      memberId: member_id,
      message: "Message",
      title: "Title",
      topic: "Topic"
    };

    const r_no = await this.secureStorageService.getValue('r_number');
    this.authService.sendVerification(postData, r_no!).subscribe(
      {
        next: (response) => {
          if (response.code === '200') {
            this.helper.presentToast('Verification has sent to your email');
          } else {
            /** show something */
          }
        },
        error: (error) => {
          this.helper.presentToast('Something went wrong!')
          console.error('Error fetching data', error);
        }
      }
    );
  }

  switchType() {

    this.eyeActive = !this.eyeActive;

    this.ptype = this.ptype === 'password' ? 'text' : 'password';

    this.cdf.detectChanges()
  }

  handleRefresh(event: any) {
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

  onCountryChange(event: any) {
    const selectedCountry = event.detail.value;
    switch (selectedCountry) {
      case 'korea':
        this.initialNumber = '82';
        break;
      case 'thai':
        this.initialNumber = '66';
        break;
      case 'other':
        this.initialNumber = '';
        break;
      default:
        this.initialNumber = '';
        break;
    }
  }
}
