import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  eyeActive: boolean = true;

  transactionId:any;

  constructor(private authService: AuthService){

  }

  getTransactionId(){
    return this.transactionId =
      this.authService.getDateTimeNow() +
      '-' +
      this.authService.generateRandomNo();
  }

}
