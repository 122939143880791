import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
})
export class AlertMessageComponent {
  @Input() isAlertOpen: boolean = false;
  constructor() { }

  alertButtons = [
    {
      text: 'Okay',
      handler: () => {
        this.setOpen(false);
      },
    },
  ];

  setOpen(isOpen: boolean) {
    this.isAlertOpen = isOpen;
  }
}
