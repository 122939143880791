import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SecureStorageService } from 'src/app/core/services/storage/secure-storage.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

const API_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private httpClient: HttpClient,) {
  }

  getHomeTotalBalance(id: string): Observable<any> {
    return this.httpClient.get(API_URL + 'customer/home/summary');
  }

  getCryptoDasboardData(){
    return this.httpClient.get(API_URL + 'customer/home/summary');
  }
}