import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { off } from 'process';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { NFTData } from './nft.model';

const API_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  /** for data update */

  private balanceAfterTopUpSuccessSubject = new BehaviorSubject<boolean>(false);
  balanceAfterTopUpSuccessSubject$ = this.balanceAfterTopUpSuccessSubject.asObservable();

  private balanceAfterRefundSuccessSubject = new BehaviorSubject<boolean>(false);
  balanceAfterRefundSuccessSubject$ = this.balanceAfterRefundSuccessSubject.asObservable();

  private balanceAfterTransferSuccessSubject = new BehaviorSubject<boolean>(false);
  balanceAfterTransferSuccessSubject$ = this.balanceAfterTransferSuccessSubject.asObservable();


  constructor(private httpClient: HttpClient) { }

  setBalanceAfterTopUpSuccess(value: boolean) {
    this.balanceAfterTopUpSuccessSubject.next(value);
  }

  setBalanceAfterRefundSuccess(value: boolean) {
    this.balanceAfterRefundSuccessSubject.next(value);
  }

  setBalanceAfterTransferSuccess(value: boolean) {
    this.balanceAfterTransferSuccessSubject.next(value);
  }


  // getCryptoList(walletId: string, limit: number, offset: number) {
  //   return this.httpClient.get(API_URL + 'wallet/' + walletId + '/tokenGroup?limit=' + limit + '&offset=' + offset);
  // }

  getCryptoList(walletId: string, limit: number, offset: number, tokenGroupName?: any) {

    let url = API_URL + 'wallet/' + walletId;

    if (limit) {
      url = url + '/tokenGroup?limit=' + limit;
    }
    if (offset) {
      url = url + '&offset=' + offset;
    }
    if (tokenGroupName) {
      url = url + '&tokenGroupName=' + tokenGroupName
    }
    return this.httpClient.get(url);

  }

  getCoin(id: any, tokenGpName?: any): Observable<any> {
    return this.httpClient.get(API_URL + 'wallet/' + id + '/tokenGroup?limit=10&offset=1')
  }

  getCoinIconAttachment(icon: any) {
    return this.httpClient.get(API_URL + 'attach/opt/workspace/assests/icon/' + icon)
  }

  getNetwork(id: any, symbol: any): Observable<any> {
    return this.httpClient.get(API_URL + 'wallet/' + id + '/tokenGroup/tokenAddress?limit=10&offset=1&tokenGroupSymbol=' + symbol)
  }

  getCryptoBalance(id: any, symbol: any, filter: any, limit: any, offset: any) {
    let url = API_URL + 'wallet/' + id;

    if (limit) {
      url = url + '/tokenGroup/tokenAddress?limit=' + limit + "&"
    }
    if (offset) {
      url = url + 'offset=' + offset + '&'
    }
    if (symbol) {
      url = url + 'tokenGroupSymbol=' + symbol + '&'
    }
    if (filter) {
      url = url + 'networkName=' + filter + '&'
    }

    return this.httpClient.get(url);
  }

  getNFTList(walletId: string, limit: number, offset: number): Observable<NFTData[]> {
    let url = API_URL + 'nft/' + walletId + '/nfts'

    if (limit) {
      url = url + "?limit=" + limit + '&'
    }
    if (offset) {
      url = url + 'offset=' + offset
    }

    return this.httpClient.get<NFTData[]>(url);
  }

  getCryptoDasboardData() {
    return this.httpClient.get(API_URL + 'customer/home/summary');
  }

  getNetworkData(id: any, symbol: any, limit: any, offset: any, name?: any) {
    let url = API_URL + 'wallet/' + id;

    if (limit) {
      url = url + '/tokenGroup/tokenAddress?limit=' + 10;
    }
    if (offset) {
      url = url + '&offset=' + offset;
    }
    if (symbol) {
      url = url + '&tokenGroupSymbol=' + symbol;
    }
    if (name) {
      url = url + '&networkName=' + name;
    }
    return this.httpClient.get(url);
  }

  triggerFunction = new EventEmitter<void>();

  cardTopUp(cardID: string, reqBody: any): Observable<any> {
    return this.httpClient.post(API_URL + 'card/' + parseInt(cardID) + '/topUp', reqBody);
  }
}
