import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Network, NetworkStatus } from '@capacitor/network';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  private isOnline: boolean = true;

  private isOnlineSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isOnline$: Observable<boolean> = this.isOnlineSubject.asObservable();

  constructor(private http: HttpClient) {
    this.initializeNetworkEvents()
    this.logCurrentNetworkStatus()
  }

  private initializeNetworkEvents(): void {
    Network.addListener('networkStatusChange', (status: NetworkStatus) => {
      this.isOnlineSubject.next(status.connected);
      console.log('Network status changed', status);
    });
  }

  private async logCurrentNetworkStatus(): Promise<void> {
    const status = await Network.getStatus();
    this.isOnlineSubject.next(status.connected);
    console.log('Network status:', status);
  }
}
